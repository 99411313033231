import { RawMeat } from '../items/RawMeat';
import { IMob } from './IMob';
export class Rabbit extends IMob {
    constructor(context) {
        super(context);
        if (this.displayName === undefined) {
            this.displayName = null;
        }
        this.health = 10;
        if (context.getRandom().nextInt(100) < 10) {
            this.displayName = 'Killer-Kaninchen';
        }
        else {
            this.displayName = 'Kaninchen';
        }
    }
    /**
     *
     * @return {number}
     */
    getStrength() {
        if (this.displayName === 'Killer-Kaninchen')
            return 100;
        return 1;
    }
    /**
     *
     * @return {number}
     */
    getHealth() {
        if (this.displayName === 'Killer-Kaninchen')
            return this.health;
        return this.health;
    }
    /**
     *
     * @param {number} health
     */
    setHealth(health) {
        this.health = health;
    }
    /**
     *
     * @return {IItem}
     */
    getDrop() {
        return new RawMeat(this.context);
    }
    /**
     *
     * @return {number}
     */
    getExperience() {
        return 10;
    }
    /**
     *
     */
    onBeaten() {
        if (!(this.displayName === 'Killer-Kaninchen'))
            this.context
                .getView()
                .console()
                .println$java_lang_String('Das war es mit dem niedlichen Kaninchen. Ob es schmeckt?')
                .delay(800);
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return this.displayName;
    }
    /**
     *
     */
    onUseFromWorld() {
        if (this.displayName === 'Killer-Kaninchen')
            this.context
                .getView()
                .console()
                .println$java_lang_String('Das gef\u00e4hrliche Killer-Kaninchen von Caerbannog!');
        else
            this.context
                .getView()
                .console()
                .println$java_lang_String('Ein kleines niedliches Kaninchen.');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'n';
    }
}
Rabbit['__class'] = 'de.coco.mindLinq.world.mobs.Rabbit';
