import { Tile } from './world/Tile';
import { DarkAdmin } from './world/mobs/DarkAdmin';
import { MysteriousDoor } from './world/objects/MysteriousDoor';
import { GameContext } from './GameContext';
import { Controls } from './Controls';
import { Player } from './Player';
export class Game {
    constructor(view, stats) {
        if (this.view === undefined) {
            this.view = null;
        }
        if (this.player === undefined) {
            this.player = null;
        }
        if (this.controls === undefined) {
            this.controls = null;
        }
        if (this.bossAppeared === undefined) {
            this.bossAppeared = false;
        }
        this.playerHasWon = false;
        if (this.context === undefined) {
            this.context = null;
        }
        this.view = view;
        this.context = new GameContext(view, stats);
        this.player = new Player(this.context, new Tile(this.context));
        this.controls = new Controls();
        this.controls.addControllListener(this);
    }
    async start() {
        this.view.tileStatus(this.player.getCurrentTile());
        this.view.inventoryStatus(this.player.getIntentoryInfo());
        while (!this.playerHasWon) {
            {
                if (this.player.getHealth() <= 0) {
                    this.view.playerDied();
                    this.gameEnd();
                    return;
                }
                this.controls.parsePlayerInput(await this.view.getInputParts());
            }
        }
    }
    /*private*/ gameEnd() {
        this.view.endGameSummary(this.player);
    }
    /**
     *
     * @param {string} direction
     */
    onMoveCommand(direction) {
        this.player.move(direction);
        this.view.tileStatus(this.player.getCurrentTile());
        this.view.inventoryStatus(this.player.getIntentoryInfo());
    }
    /**
     *
     * @param {string} itemName
     */
    onTakeCommand(itemName) {
        if (this.player.getCurrentTile().hasEnemy()) {
            this.view.blockedByEnemy(this.player.getCurrentTile().getEnemy());
            return;
        }
        if (itemName == null) {
            this.view.noItemSelected$();
            return;
        }
        const item = this.player.getCurrentTile().getItem(itemName);
        if (item != null) {
            this.player.take(item);
            this.view.inventoryStatus(this.player.getIntentoryInfo());
        }
        else {
            this.view.noItemSelected$java_lang_String(itemName);
        }
    }
    /**
     *
     * @param {string} itemName
     */
    onDropCommand(itemName) {
        if (itemName == null) {
            this.view.noItemSelected$();
            return;
        }
        const itemToDrop = this.player.getItem(itemName);
        if (itemToDrop == null) {
            this.view.doesNotExist(itemName);
            return;
        }
        this.player.drop(itemToDrop);
    }
    /**
     *
     * @param {string} objectName
     */
    onUseCommand(objectName) {
        if (objectName == null) {
            this.view.noItemSelected$();
            return;
        }
        const itemToUse = this.player.getItem(objectName);
        if (itemToUse == null) {
            const object = this.player
                .getCurrentTile()
                .getObject(objectName);
            if (object != null) {
                if (object != null && object instanceof MysteriousDoor) {
                    if (this.player.getCurrentTile().hasEnemy()) {
                        this.view.blockedByEnemy(this.player.getCurrentTile().getEnemy());
                        return;
                    }
                    else {
                        if (!this.bossAppeared) {
                            this.player.getCurrentTile().addMob(new DarkAdmin(this.context));
                            this.view.endbossSpawns();
                            this.view.inventoryStatus(this.player.getIntentoryInfo());
                            this.bossAppeared = true;
                            return;
                        }
                        else {
                            object.onUseFromWorld();
                            this.view.gameWonOutro();
                            this.playerHasWon = true;
                            this.gameEnd();
                            return;
                        }
                    }
                }
                object.onUseFromWorld();
                return;
            }
            const item = this.player.getCurrentTile().getItem(objectName);
            if (item != null) {
                item.onUseFromWorld();
                this.view.canBeUsedFromInventory();
                return;
            }
            const mob = this.player.getCurrentTile().getMob(objectName);
            if (mob != null) {
                mob.onUseFromWorld();
                return;
            }
            this.view.doesNotExist(objectName);
        }
        else {
            this.player.use(itemToUse);
        }
    }
    /**
     *
     */
    onUnknownCommand() {
        this.view.unknownCommand();
    }
}
Game['__class'] = 'de.coco.mindLinq.Game';
Game['__interfaces'] = ['de.coco.mindLinq.IControlListener'];
