import { GameRules } from './GameRules';
import { ITeleporter } from './world/items/ITeleporter';
import { IActivatable } from './world/objects/IActivatable';
export class Player {
    constructor(context, startingTile) {
        this.BASE_HEALTH = 100.0;
        if (this.currentTile === undefined) {
            this.currentTile = null;
        }
        if (this.inventory === undefined) {
            this.inventory = null;
        }
        this.health = this.BASE_HEALTH;
        this.experiencePoints = 0;
        if (this.context === undefined) {
            this.context = null;
        }
        this.currentTile = startingTile;
        this.currentTile.enter();
        this.context = context;
        this.inventory = [];
    }
    /*private*/ setHealth(health) {
        this.health = Math.min(health, this.maxHealth());
    }
    getHealth() {
        return this.health;
    }
    /*private*/ maxHealth() {
        return this.BASE_HEALTH + 50.0 * (this.getLevel(this.experiencePoints) - 1);
    }
    /*private*/ setExperiencePoints(points) {
        if (this.getLevel(points) > this.getLevel(this.experiencePoints)) {
            this.health = this.maxHealth();
            this.context.getView().levelUp(this.getLevel(points));
        }
        this.experiencePoints = points;
    }
    getExperiencePoints() {
        return this.experiencePoints;
    }
    getLevel(points) {
        if (points < 800)
            return 1;
        else if (points < 2600)
            return 2;
        else if (points < 5600)
            return 3;
        else if (points < 10000)
            return 4;
        else
            return 5;
    }
    use(item) {
        if (item.isActivator() && !this.currentTile.hasEnemy()) {
            this.activateWith(item);
        }
        else if (item.isEdible()) {
            this.eat(item);
        }
        else if (this.currentTile.hasEnemy()) {
            this.attackWith(item);
        }
        else if (item != null && item instanceof ITeleporter) {
            if (item.getDestination() == null) {
                item.setDestination(this.currentTile);
            }
            else {
                this.teleport(item);
            }
        }
        else {
            if (!item.onUseFromInventory())
                this.context.getView().objectInspection(item);
            /* remove */ else
                ((a) => {
                    let index = a.indexOf(item);
                    if (index >= 0) {
                        a.splice(index, 1);
                        return true;
                    }
                    else {
                        return false;
                    }
                })(this.inventory);
        }
    }
    take(item) {
        if (this.addToInventory(item)) {
            this.context.getView().playerTakesItem();
            this.currentTile.removeItem(item);
        }
    }
    drop(item) {
        this.currentTile.addItem(item);
        /* remove */ ((a) => {
            let index = a.indexOf(item);
            if (index >= 0) {
                a.splice(index, 1);
                return true;
            }
            else {
                return false;
            }
        })(this.inventory);
        item.onDrop();
        this.context.getView().itemWasDroppedOnGround(item);
    }
    move(direction) {
        switch (direction) {
            case 'n':
                this.moveTo(this.currentTile.getTileNorth());
                break;
            case 'w':
                this.moveTo(this.currentTile.getTileWest());
                break;
            case 'e':
                this.moveTo(this.currentTile.getTileEast());
                break;
            case 's':
                this.moveTo(this.currentTile.getTileSouth());
                break;
        }
    }
    /*private*/ moveTo(newTile) {
        const accessible = newTile.isAccessible();
        if (accessible) {
            this.currentTile = newTile;
            this.currentTile.enter();
        }
        else
            this.context.getView().tileBlocked(newTile);
    }
    /*private*/ eat(item) {
        item.onEat();
        this.setHealth(this.getHealth() + item.getValue());
        this.context.getView().playerHasEaten(item, this.getHealth());
        /* remove */ ((a) => {
            let index = a.indexOf(item);
            if (index >= 0) {
                a.splice(index, 1);
                return true;
            }
            else {
                return false;
            }
        })(this.inventory);
    }
    /*private*/ activateWith(item) {
        {
            let array128 = this.currentTile.getObjects();
            for (let index127 = 0; index127 < array128.length; index127++) {
                let o = array128[index127];
                {
                    if (o != null && o instanceof IActivatable) {
                        this.context.getView().tryObjectActivation(o, item);
                        const success = o.activate(item);
                        if (success) {
                            const loot = o.getLoot();
                            if (item.onUseFromInventory()) {
                                this.context.getView().itemWasUsed(item);
                                /* remove */ ((a) => {
                                    let index = a.indexOf(item);
                                    if (index >= 0) {
                                        a.splice(index, 1);
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                })(this.inventory);
                            }
                            if (loot != null) {
                                if (
                                /* size */ this.inventory.length >=
                                    GameRules.INVENTORY_SPACE) {
                                    this.context.getView().inventoryFull();
                                    this.context.getView().itemWasDroppedOnGround(loot);
                                    this.currentTile.addItem(loot);
                                }
                                else {
                                    this.context.getView().playerGotItem(loot);
                                    /* add */ this.inventory.push(loot) > 0;
                                }
                            }
                            return;
                        }
                        else {
                            if (item.isEdible())
                                this.eat(item);
                            else
                                this.context.getView().canNotActivateWith(item, o);
                            return;
                        }
                    }
                }
            }
        }
        this.context.getView().noObjectToBeActivated();
    }
    /*private*/ attackWith(item) {
        const enemy = this.currentTile.getEnemy();
        const enemyDamage = this.calcEnemyDamage(item);
        const playerDamage = this.calcPlayerDamage(enemy);
        enemy.setHealth(enemy.getHealth() - enemyDamage);
        this.setHealth(this.getHealth() - playerDamage);
        this.context
            .getView()
            .playerAttacksEnemy(enemy, item, this.getHealth(), enemyDamage, playerDamage);
        if (this.getHealth() > 0 && enemy.getHealth() <= 0) {
            this.context.getStats().incrementEnemiesBeaten();
            this.context.getView().enemyBeaten(enemy);
            enemy.onBeaten();
            this.setExperiencePoints(this.getExperiencePoints() + enemy.getExperience());
            const drop = enemy.getDrop();
            if (drop != null) {
                if (this.addToInventory(drop)) {
                    this.context.getView().playerGotItem(drop);
                }
                else {
                    this.currentTile.addItem(drop);
                    this.context.getView().itemWasDroppedOnGround(drop);
                }
            }
            this.currentTile.removeMob(enemy);
        }
    }
    /*private*/ calcEnemyDamage(item) {
        return Math.round(item.getValue() * (1 + this.getLevel(this.experiencePoints) / 20.0) +
            ((this.context.getRandom().nextInt(6) + 10.0) / 100.0) * item.getValue());
    }
    /*private*/ calcPlayerDamage(enemy) {
        return Math.round(enemy.getStrength() +
            ((this.context.getRandom().nextInt(6) + 3.0) / 100.0) *
                enemy.getHealth());
    }
    /*private*/ teleport(item) {
        item.onUseFromInventory();
        this.moveTo(item.getDestination());
        this.context.getView().tileStatus(this.currentTile);
        this.context.getView().inventoryStatus(this.getIntentoryInfo());
    }
    /*private*/ addToInventory(item) {
        if ( /* size */this.inventory.length >= GameRules.INVENTORY_SPACE) {
            this.context.getView().inventoryFull();
            return false;
        }
        /* add */ this.inventory.push(item) > 0;
        item.onTake();
        return true;
    }
    getItem(name) {
        for (let index129 = 0; index129 < this.inventory.length; index129++) {
            let item = this.inventory[index129];
            if (item.getDisplayName().toLowerCase() === name.toLowerCase())
                return item;
        }
        return null;
    }
    getCurrentTile() {
        return this.currentTile;
    }
    getIntentoryInfo() {
        const str = {
            str: '',
            toString: function () {
                return this.str;
            },
        };
        for (let i = 0; i < /* size */ this.inventory.length; i++) {
            /* append */ ((sb) => {
                sb.str +=
                    i !== /* size */ this.inventory.length - 1 ? ', ' : '';
                return sb;
            })(
            /* append */ ((sb) => {
                sb.str += this.inventory[i].getDisplayName();
                return sb;
            })(str));
        }
        return /* toString */ str.str;
    }
}
Player['__class'] = 'de.coco.mindLinq.Player';
