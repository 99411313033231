import { IItem } from './IItem';
export class RawFisch extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return true;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return true;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 10;
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() { }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return true;
    }
    /**
     *
     */
    onEat() { }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Fisch';
    }
    /**
     *
     */
    onUseFromWorld() { }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
}
RawFisch['__class'] = 'de.coco.mindLinq.world.items.RawFisch';
