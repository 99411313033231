/* Generated from Java with JSweet 3.0.0 - http://www.jsweet.org */
export class GameStats {
    constructor() {
        this.placesVisited = 0;
        this.cluesFound = 0;
        this.enemiesBeaten = 0;
        this.mysteriousDoorFound = false;
    }
    getPlacesVisited() {
        return this.placesVisited;
    }
    incrementPlacesVisited() {
        this.placesVisited++;
    }
    getCluesFound() {
        return this.cluesFound;
    }
    incrementCluesFound() {
        this.cluesFound++;
    }
    getEnemiesBeaten() {
        return this.enemiesBeaten;
    }
    incrementEnemiesBeaten() {
        this.enemiesBeaten++;
    }
    setMysteriousDoorFound() {
        this.mysteriousDoorFound = true;
    }
    getMysteriousDoorFound() {
        return this.mysteriousDoorFound;
    }
}
GameStats["__class"] = "de.coco.mindLinq.GameStats";
