export class Controls {
    constructor() {
        if (this.controlListener === undefined) {
            this.controlListener = null;
        }
    }
    addControllListener(controlListener) {
        this.controlListener = controlListener;
    }
    parsePlayerInput(parts) {
        switch (parts[0]) {
            case 'n' /* NORTH_KEY */:
            case 's' /* SOUTH_KEY */:
            case 'w' /* WEST_KEY */:
            case 'e' /* EAST_KEY */:
                this.controlListener.onMoveCommand(parts[0]);
                break;
            case 't' /* TAKE_KEY */:
                this.controlListener.onTakeCommand(parts.length < 2 ? null : parts[1]);
                break;
            case 'd' /* DROP_KEY */:
                this.controlListener.onDropCommand(parts.length < 2 ? null : parts[1]);
                break;
            case 'u' /* USE_KEY */:
                this.controlListener.onUseCommand(parts.length < 2 ? null : parts[1]);
                break;
            default:
                this.controlListener.onUnknownCommand();
                break;
        }
    }
}
Controls.NORTH_KEY = 'n';
Controls.SOUTH_KEY = 's';
Controls.WEST_KEY = 'w';
Controls.EAST_KEY = 'e';
Controls.TAKE_KEY = 't';
Controls.DROP_KEY = 'd';
Controls.USE_KEY = 'u';
Controls['__class'] = 'de.coco.mindLinq.Controls';
