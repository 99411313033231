import { IItem } from './IItem';
export class Sandwich extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return true;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 80;
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'K\u00e4sestulle';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Das sieht nahrhaft und st\u00e4rkend aus!');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'f';
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() { }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return true;
    }
    /**
     *
     */
    onEat() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('K\u00e4sebrot ist ein gutes Brot.')
            .delay(800);
    }
}
Sandwich['__class'] = 'de.coco.mindLinq.world.items.Sandwich';
