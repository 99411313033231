/* Generated from Java with JSweet 3.0.0 - http://www.jsweet.org */
export class GameRules {
}
GameRules.MIN_ITEMS_PER_TILE = 0;
GameRules.MAX_ITEMS_PER_TILE = 3;
GameRules.MIN_ENEMY_PER_TILE = 0;
GameRules.MAX_ENEMY_PER_TILE = 1;
GameRules.MIN_WORLD_OBJECTS_PER_TILE = 1;
GameRules.MAX_WORLD_OBJECTS_PER_TILE = 1;
GameRules.CHANCE_FOR_WORLD_END = 30;
GameRules.PLACES_VISITED_BEFORE_WORLD_END = 10;
GameRules.CLUES_COUNT = 5;
GameRules.INVENTORY_SPACE = 7;
GameRules['__class'] = 'de.coco.mindLinq.GameRules';
