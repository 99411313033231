import { IItem } from './IItem';
export class Stone extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return true;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return -20;
    }
    /**
     *
     */
    onDrop() {
        this.context
            .getView()
            .ai()
            .comment$java_lang_String("Vorsicht! Verletz' niemanden damit!");
    }
    /**
     *
     */
    onTake() { }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return false;
    }
    /**
     *
     */
    onEat() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Das hat deinem Magen nicht so gut bekommen.');
        if (this.context.getRandom().nextBoolean()) {
            this.context
                .getView()
                .ai()
                .comment('Ich zweifle nicht an deine geistigen Fähigkeiten. Nein, überhaupt nicht.');
        }
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Stein';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Ein wundersch\u00f6ner Stein!');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
}
Stone['__class'] = 'de.coco.mindLinq.world.items.Stone';
