import { IItem } from './IItem';
export class CookedMeat extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return true;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 120;
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() { }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return true;
    }
    /**
     *
     */
    onEat() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Eigentich bist du Vegetarier, aber dieses Steak ist sehr gut.');
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Steak';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Ein gebratenes St\u00fcck Fleisch.');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'n';
    }
}
CookedMeat['__class'] = 'de.coco.mindLinq.world.items.CookedMeat';
