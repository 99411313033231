import { GameRules } from '../../GameRules';
import { ObjectSpawner } from '../ObjectSpawner';
import { Key } from '../items/Key';
import { Letter } from '../items/Letter';
import { IActivatable } from './IActivatable';
import { SpawnTypes } from '../SpawnTypes';
export class Chest extends IActivatable {
    constructor(context) {
        super(context);
        if (this.loot === undefined) {
            this.loot = null;
        }
        if (context.getRandom().nextInt(100) < 80 &&
            context.getStats().getCluesFound() < GameRules.CLUES_COUNT)
            this.loot = new Letter(context);
        else
            this.loot = ObjectSpawner.getInstance().newItem(context, SpawnTypes.CHEST);
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Truhe';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Egal wie sehr du es versuchst, die Truhe l\u00e4sst sich nicht \u00f6ffnen.');
        this.context
            .getView()
            .console()
            .println$java_lang_String('Vielleicht findest du einen Schl\u00fcssel?');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'f';
    }
    /**
     *
     * @param {IItem} item
     * @return {boolean}
     */
    activate(item) {
        if (item != null && item instanceof Key) {
            this.context
                .getView()
                .console()
                .println$java_lang_String('Du konntest die Truhe mit dem Schl\u00fcssel \u00f6ffnen!');
            return true;
        }
        return false;
    }
    /**
     *
     * @return {IItem}
     */
    getLoot() {
        const item = this.loot;
        if (this.loot == null)
            this.context.getView().noLootFromActivatable();
        this.loot = null;
        return item;
    }
}
Chest['__class'] = 'de.coco.mindLinq.world.objects.Chest';
