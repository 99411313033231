import { Axe } from '../items/Axe';
import { CookedFish } from '../items/CookedFish';
import { CookedMeat } from '../items/CookedMeat';
import { RawFisch } from '../items/RawFisch';
import { RawMeat } from '../items/RawMeat';
import { Branch } from '../items/weapons/Branch';
import { IActivatable } from './IActivatable';
export class Campfire extends IActivatable {
    constructor(context) {
        super(context);
        this.item = null;
        this.burning = false;
    }
    /**
     *
     * @param {IItem} item
     * @return {boolean}
     */
    activate(item) {
        if (!this.burning) {
            const success = item != null && item instanceof Branch;
            if (success) {
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Pl\u00f6tzlich entsteht ein Funke und Flammen steigen auf!');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Vielleicht kannst du jetzt Dinge damit braten?');
                this.burning = true;
                return true;
            }
            else {
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Die Feuerstelle ist kalt. Vielleicht gibt es eine M\u00f6glichkeit, ein Feuer zu machen?.');
            }
            return false;
        }
        else {
            const success = (item != null && item instanceof Branch) ||
                (item != null && item instanceof Axe) ||
                (item != null && item instanceof RawMeat) ||
                (item != null && item instanceof RawFisch);
            if (success) {
                if (item != null && item instanceof RawMeat) {
                    this.context
                        .getView()
                        .console()
                        .println$java_lang_String('Du br\u00e4tst das Fleisch \u00fcber dem Lagerfeuer.');
                    this.item = item;
                    this.burning = false;
                }
                else if (item != null && item instanceof RawFisch) {
                    this.context
                        .getView()
                        .console()
                        .println$java_lang_String('Du br\u00e4tst den Fisch \u00fcber dem Lagerfeuer.');
                    this.item = item;
                    this.burning = false;
                }
                else {
                    this.context
                        .getView()
                        .console()
                        .println$java_lang_String(item.getDisplayName() + ' verbrennt.');
                    this.context
                        .getView()
                        .console()
                        .println$java_lang_String('Du siehst zu wie die Flammen kurz hochlodern und wieder zusammenfallen.');
                }
            }
            return success;
        }
    }
    /**
     *
     * @return {IItem}
     */
    getLoot() {
        if (this.item != null &&
            this.item != null &&
            this.item instanceof RawMeat) {
            this.item = null;
            return new CookedMeat(this.context);
        }
        if (this.item != null &&
            this.item != null &&
            this.item instanceof RawFisch) {
            this.item = null;
            return new CookedFish(this.context);
        }
        return null;
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return this.burning ? 'Lagerfeuer' : 'Feuerstelle';
    }
    /**
     *
     */
    onUseFromWorld() {
        if (this.burning) {
            this.context
                .getView()
                .console()
                .println$java_lang_String('Ein Lagerfeuer.');
            this.context
                .getView()
                .console()
                .println$java_lang_String('Vielleicht kannst du damit Dinge braten?');
        }
        else {
            this.context
                .getView()
                .console()
                .println$java_lang_String('Eine Feuerstelle. Vielleicht gibt es eine M\u00f6glichkeit, sie anzuz\u00fcnden?');
        }
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return this.burning ? 'n' : 'f';
    }
}
Campfire['__class'] = 'de.coco.mindLinq.world.objects.Campfire';
