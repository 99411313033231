import { Key } from '../items/Key';
import { RawMeat } from '../items/RawMeat';
import { IMob } from './IMob';
export class Boar extends IMob {
    constructor(context) {
        super(context);
        this.health = 50;
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Wildschwein';
    }
    /**
     *
     * @return {number}
     */
    getStrength() {
        if (this.context.getRandom().nextInt(3) < 1)
            this.context.getView().console().println$java_lang_String('Grunz');
        return 8;
    }
    /**
     *
     * @return {number}
     */
    getHealth() {
        return this.health;
    }
    /**
     *
     * @param {number} health
     */
    setHealth(health) {
        this.health = health;
    }
    /**
     *
     * @return {IItem}
     */
    getDrop() {
        const n = this.context.getRandom().nextInt(100);
        if (n < 20) {
            return new Key(this.context);
        }
        return new RawMeat(this.context);
    }
    /**
     *
     * @return {number}
     */
    getExperience() {
        return 300;
    }
    /**
     *
     */
    onBeaten() { }
    /**
     *
     */
    onUseFromWorld() {
        this.context.getView().mobInspection(this);
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'n';
    }
}
Boar['__class'] = 'de.coco.mindLinq.world.mobs.Boar';
