/* Generated from Java with JSweet 3.0.0 - http://www.jsweet.org */
import { Random } from './Random';
export class GameContext {
    constructor(view, stats) {
        if (this.view === undefined) {
            this.view = null;
        }
        if (this.stats === undefined) {
            this.stats = null;
        }
        if (this.random === undefined) {
            this.random = null;
        }
        this.view = view;
        this.stats = stats;
        this.random = new Random();
    }
    getView() {
        return this.view;
    }
    getStats() {
        return this.stats;
    }
    getRandom() {
        return this.random;
    }
}
GameContext['__class'] = 'de.coco.mindLinq.GameContext';
