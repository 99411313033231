import { IItem } from './IItem';
export class Mushroom extends IItem {
    constructor(context) {
        super(context);
        if (this.luckyNumber === undefined) {
            this.luckyNumber = 0;
        }
        this.luckyNumber = context.getRandom().nextInt(2);
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return true;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        if (this.luckyNumber === 0)
            return -40;
        return 100;
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Dieser Pilz sieht total unscheinbar aus. Solltest du ihn probieren?');
    }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return true;
    }
    /**
     *
     */
    onEat() {
        if (this.luckyNumber === 0) {
            this.context
                .getView()
                .console()
                .println$java_lang_String('Dieser Pilz scheint giftig gewesen zu sein.');
            this.context.getView().ai().comment('Eine grandiose Idee.');
        }
        if (this.luckyNumber === 1) {
            this.context.getView().console().println$java_lang_String('Huuuuuuu');
            this.context.getView().console().delay(1000);
            this.context
                .getView()
                .console()
                .println$java_lang_String('Du f\u00fchlst dich so leeeicht...');
            this.context.getView().console().delay(2000);
            this.context.getView().ai().comment$java_lang_String('Alles okay?');
            this.context.getView().console().delay(500);
            this.context
                .getView()
                .console()
                .println$java_lang_String('Alles ist so sch\u00f6\u00f6\u00f6n...');
            this.context.getView().console().delay(2000);
            this.context.getView().ai().comment$java_lang_String('Hallo?');
            this.context.getView().console().delay(500);
            this.context
                .getView()
                .console()
                .println$java_lang_String('Dir ist alles egal, du betrachtest einfach die Wolken.');
            this.context.getView().console().delay(2000);
            this.context.getView().ai().comment$java_lang_String('Halloooo?!');
            this.context.getView().console().delay(500);
            this.context
                .getView()
                .console()
                .println$java_lang_String('Was ist das? Ein Einhorn?');
            this.context.getView().console().delay(2000);
            this.context.getView().ai().comment$java_lang_String('...');
        }
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Pilz';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Ein unscheinbarer Pilz.');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
}
Mushroom['__class'] = 'de.coco.mindLinq.world.items.Mushroom';
