import { Axe } from '../items/Axe';
import { Branch } from '../items/weapons/Branch';
import { Chainsaw } from '../items/weapons/Chainsaw';
import { IActivatable } from './IActivatable';
export class Tree extends IActivatable {
    constructor(context) {
        super(context);
        if (this.chopped === undefined) {
            this.chopped = false;
        }
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return this.chopped ? 'Baumstumpf' : 'Baum';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context.getView().objectInspection(this);
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
    /**
     *
     * @param {IItem} item
     * @return {boolean}
     */
    activate(item) {
        const success = (item != null && item instanceof Chainsaw) ||
            (item != null && item instanceof Axe);
        if (success) {
            if (this.chopped) {
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Dieser Baum wurde bereits gef\u00e4llt.');
                return false;
            }
            this.context
                .getView()
                .console()
                .println$java_lang_String('Du f\u00e4llst den Baum.');
            this.chopped = true;
        }
        return success;
    }
    /**
     *
     * @return {IItem}
     */
    getLoot() {
        return new Branch(this.context);
    }
}
Tree['__class'] = 'de.coco.mindLinq.world.objects.Tree';
