import { GameRules } from '../../GameRules';
import { ObjectSpawner } from '../ObjectSpawner';
import { Axe } from '../items/Axe';
import { Key } from '../items/Key';
import { Letter } from '../items/Letter';
import { IActivatable } from './IActivatable';
import { SpawnTypes } from '../SpawnTypes';
export class House extends IActivatable {
    constructor(context) {
        super(context);
        if (this.loot === undefined) {
            this.loot = null;
        }
        if (context.getRandom().nextInt(100) < 60 &&
            context.getStats().getCluesFound() < GameRules.CLUES_COUNT)
            this.loot = new Letter(context);
        else
            this.loot = ObjectSpawner.getInstance().newItem(context, SpawnTypes.CHEST);
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Haus';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Das Haus sieht verlassen aus.');
        this.context
            .getView()
            .console()
            .println$java_lang_String$int('Vielleicht findest du etwas n\u00fctzliches darin?', 800);
        this.context
            .getView()
            .console()
            .println$java_lang_String$int('Die T\u00fcr ist verschlossen.', 1000);
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'n';
    }
    /**
     *
     * @param {IItem} item
     * @return {boolean}
     */
    activate(item) {
        if ((item != null && item instanceof Key) ||
            (item != null && item instanceof Axe)) {
            this.context
                .getView()
                .console()
                .println$java_lang_String('Langsam n\u00e4herst du dich dem Haus.');
            this.context
                .getView()
                .console()
                .println$java_lang_String$int('Die T\u00fcr \u00f6ffnet sich mit einem Knarzen.', 800);
            this.context
                .getView()
                .console()
                .println$java_lang_String$int('Du schaust dich um. Alles ist mit einer dicken Staubschicht \u00fcberzogen.', 1000);
            this.context
                .getView()
                .console()
                .println$java_lang_String$int('Nachdem du alles mitgenommen hast, was du n\u00fctzlich findest, verl\u00e4sst du das Haus.', 1000);
            this.context.getView().console().delay(1000);
            return true;
        }
        return false;
    }
    /**
     *
     * @return {IItem}
     */
    getLoot() {
        const item = this.loot;
        if (this.loot == null)
            this.context.getView().noLootFromActivatable();
        this.loot = null;
        return item;
    }
}
House['__class'] = 'de.coco.mindLinq.world.objects.House';
