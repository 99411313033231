import { ObjectSpawner } from '../ObjectSpawner';
import { FishingRod } from '../items/FishingRod';
import { RawFisch } from '../items/RawFisch';
import { IActivatable } from './IActivatable';
export class Creek extends IActivatable {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Bach';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Ein kleiner Bach.');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
    /**
     *
     * @param {IItem} item
     * @return {boolean}
     */
    activate(item) {
        if (item != null && item instanceof FishingRod) {
            this.context
                .getView()
                .console()
                .println$java_lang_String('Du wirfst die Angel aus und wartest geduldig.')
                .delay(1000);
            return true;
        }
        return false;
    }
    /**
     *
     * @return {IItem}
     */
    getLoot() {
        let item;
        if (this.context.getRandom().nextBoolean())
            item = null;
        else if (this.context.getRandom().nextInt(100) < 70)
            item = new RawFisch(this.context);
        else
            item = ObjectSpawner.getInstance().newItem(this.context, null);
        if (item == null)
            this.context.getView().noLootFromActivatable();
        return item;
    }
}
Creek['__class'] = 'de.coco.mindLinq.world.objects.Creek';
