/* Generated from Java with JSweet 3.0.0 - http://www.jsweet.org */
import { AI } from './AI';
import { GameRules } from './GameRules';
import { Random } from './Random';
import { IItem } from './world/items/IItem';
import { DarkAdmin } from './world/mobs/DarkAdmin';
import { IMob } from './world/mobs/IMob';
import { MysteriousDoor } from './world/objects/MysteriousDoor';
import { TileTypes } from './world/TileTypes';
export class View {
    constructor(io, stats) {
        this.inventoryFullFirst = true;
        if (this.__console === undefined) {
            this.__console = null;
        }
        if (this.__ai === undefined) {
            this.__ai = null;
        }
        if (this.stats === undefined) {
            this.stats = null;
        }
        if (this.random === undefined) {
            this.random = null;
        }
        this.__console = io;
        this.__ai = new AI(this.__console);
        this.stats = stats;
        this.random = new Random();
    }
    inventoryStatus(str) {
        this.__console.print$java_lang_String('Inventar: [' + str + ']').newLine();
    }
    tileStatus(tile) {
        switch (tile.getType()) {
            case TileTypes.GRASS:
                this.printRandomLine$java_lang_String_A([
                    'Ein blauer strahlender Himmel. Für einen Moment vergisst du alles um dich herum und genießt nur die Stille.',
                    'Du findest dich auf einer idyllischen Wiese wieder. In der Ferne hörst du Vogelgezwitscher.',
                    'Um dich herum erstreckt sich eine weite Wiesenlandschaft.',
                    'Dich umgibt eine grüne Ebene unter klarem Himmel.',
                    'Du läufst durch kniehohes Gras. Du beobachtest eine Biene, wie sie summend von Blume zu Blume fliegt.',
                    'Du folgst einem kleinen Bach. Mal sehen wo er dich hinführt.',
                    'Leise lässt der Wind die Blätter der wenigen Bäume in der Nähe rauschen. Eine angenehme Stille umgibt dich.',
                ]);
                break;
            case TileTypes.FOREST:
                this.printRandomLine$java_lang_String_A([
                    'Du befindest dich in einem dicht bewucherten Wald.',
                    'Du gehst durch einen Wald. Es dringt kaum Licht durch das dichte Blattwerk.',
                    'Im Schatten der Bäume herumirrend findest du dich in einem Waldgebiet wieder.',
                ]);
                break;
            case TileTypes.TUNDRA:
                this.printRandomLine$java_lang_String_A([
                    'Während du weiterläufst, siehst du weiße Teilchen vom Himmel regnen. Schnee! Hier! Dich überrascht gar nichts mehr.',
                    'Du befindest dich in einer kalten, vom Schnee überdeckten Tundra.',
                    'Vereinzelte Sonnenstrahlen versuchen sich zwischen den Ästen schneebedeckter Tannen zu kämpfen. Dir fröstelt.',
                    'Es herrscht beißende Kälte. Um dich herum erstreckt sich eine weite Schneelandschaft.',
                    'Du frierst, während du dich durch tiefen Schnee kämpfst. Vielleicht kannst du irgendwo ein Feuer machen.',
                ]);
                break;
            case TileTypes.DESERT:
                this.printRandomLine$java_lang_String_A([
                    'Einen Schritt nach dem anderen bewegst du dich durch die sengende Hitze der Wüstensonne.',
                    'Du befindest dich in einer unerträglich heißen Sandwüste.',
                    'Du stapfst durch tiefen Wüstensand, das heiße Klima zerrt an deinen Kräften.',
                    'Die Sonne brennt auf dich ein, während du dich durch heißen Wüstensand quälst.',
                    'Du kommst nur langsam voran. Die Hitze macht dir zu schaffen. Es wird Zeit, dass du aus dieser Wüste rauskommst.',
                ]);
                break;
            case TileTypes.VOLCANIC:
                this.printRandomLine$java_lang_String_A([
                    'Die Gegend in der du dich jetzt befindest, ist nicht gerade einladend. Es ist heiß und der Boden besteht aus Lavagestein.',
                    'Asche und Feuer umgeben dich. In dieser Umgebung kann dich ein falscher Schritt das Leben kosten.',
                    'Mit schwerem Atem stapfst du über von Asche bedeckten Stein. In deiner Umgebung bemerkst du Ströme aus glühender Lava.',
                ]);
                break;
        }
        let foundMysteriousDoor = false;
        {
            let array131 = tile.getObjects();
            for (let index130 = 0; index130 < array131.length; index130++) {
                let o = array131[index130];
                {
                    if (o != null && o instanceof MysteriousDoor) {
                        this.playerFindsMysteriousDoor(o);
                        foundMysteriousDoor = true;
                        continue;
                    }
                    this.__console.space();
                    this.printRandomLine$de_coco_mindLinq_View_LinePrinter_A([
                        {
                            print: ((o) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String('In der Ferne erkennst du ' +
                                        this.getEinen(o.getGender()) +
                                        ' ')
                                        .underline()
                                        .print$java_lang_String(o.getDisplayName())
                                        .resetColor()
                                        .print$java_lang_String('.');
                                };
                            })(o),
                        },
                        {
                            print: ((o) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String('Du kannst ' + this.getEinen(o.getGender()) + ' ')
                                        .underline()
                                        .print$java_lang_String(o.getDisplayName())
                                        .resetColor()
                                        .print$java_lang_String(' ausmachen.');
                                };
                            })(o),
                        },
                        {
                            print: ((o) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String(this.getEin(o.getGender()).replace('e', 'E') + ' ')
                                        .underline()
                                        .print$java_lang_String(o.getDisplayName())
                                        .resetColor()
                                        .print$java_lang_String(' fällt dir auf.');
                                };
                            })(o),
                        },
                        {
                            print: ((o) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String('Links von dir ist ' + this.getEin(o.getGender()) + ' ')
                                        .underline()
                                        .print$java_lang_String(o.getDisplayName())
                                        .resetColor()
                                        .print$java_lang_String('.');
                                };
                            })(o),
                        },
                        {
                            print: ((o) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String('Rechts von dir befindet sich ' +
                                        this.getEin(o.getGender()) +
                                        ' ')
                                        .underline()
                                        .print$java_lang_String(o.getDisplayName())
                                        .resetColor()
                                        .print$java_lang_String('.');
                                };
                            })(o),
                        },
                        {
                            print: ((o) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String('Vor dir siehst du ' +
                                        this.getEinen(o.getGender()) +
                                        ' auffällige' +
                                        (o.getGender() === 'n'
                                            ? 's'
                                            : o.getGender() === 'm'
                                                ? 'n'
                                                : '') +
                                        ' ')
                                        .underline()
                                        .print$java_lang_String(o.getDisplayName())
                                        .resetColor()
                                        .print$java_lang_String('.');
                                };
                            })(o),
                        },
                    ]);
                }
            }
        }
        if (tile.getVisits() > 1) {
            this.printRandomLine$java_lang_String_A([
                ' Irgendwie kommt dir der Ort bekannt vor.',
                ' Du bist dir ziemlich sicher, dass du schon einmal hier gewesen bist.',
                ' Du warst schon einmal hier. Oder?',
            ]);
        }
        this.__console.newLine();
        const items = tile.getItems();
        if ( /* size */items.length > 0) {
            this.printRandomLine$java_lang_String_A([
                'Du findest ',
                'Du entdeckst ',
                'Dein Blick schweift über ',
            ]);
            for (let i = 0; i < /* size */ items.length; i++) {
                {
                    this.__console
                        .print$java_lang_String(this.getEinen(/* get */ items[i].getGender()) + ' ')
                        .underline()
                        .print$java_lang_String(/* get */ items[i].getDisplayName())
                        .resetColor()
                        .print$java_lang_String(i === /* size */ items.length - 2
                        ? ' und '
                        : i !== /* size */ items.length - 1
                            ? ', '
                            : '.');
                }
            }
            this.__console.newLine();
        }
        {
            let array133 = tile.getMobs();
            for (let index132 = 0; index132 < array133.length; index132++) {
                let mob = array133[index132];
                {
                    if (mob != null && mob instanceof DarkAdmin) {
                        this.__console
                            .print$java_lang_String(this.getDer(mob.getGender()).replace('d', 'D'))
                            .space()
                            .underline()
                            .red()
                            .print$java_lang_String(mob.getDisplayName())
                            .resetColor()
                            .space()
                            .print$java_lang_String('wartet auf dich.')
                            .newLine();
                        continue;
                    }
                    this.printlnRandomLine$de_coco_mindLinq_View_LinePrinter_A([
                        {
                            print: ((mob) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String('Vor dir erscheint plötzlich ' +
                                        this.getEin(mob.getGender()) +
                                        ' ')
                                        .underline()
                                        .print$java_lang_String(mob.getDisplayName())
                                        .resetColor();
                                };
                            })(mob),
                        },
                        {
                            print: ((mob) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String(this.getEin(mob.getGender()).replace('e', 'E') + ' ')
                                        .underline()
                                        .print$java_lang_String(mob.getDisplayName())
                                        .resetColor()
                                        .print$java_lang_String('. Damit hast du nicht gerechnet.');
                                };
                            })(mob),
                        },
                        {
                            print: ((mob) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String('Du entdeckst ' + this.getEinen(mob.getGender()) + ' ')
                                        .underline()
                                        .print$java_lang_String(mob.getDisplayName())
                                        .resetColor()
                                        .print$java_lang_String('. Vielleicht solltest du dich vorbeischleichen.');
                                };
                            })(mob),
                        },
                        {
                            print: ((mob) => {
                                return () => {
                                    this.__console
                                        .print$java_lang_String('Was ist das? ' +
                                        this.getEin(mob.getGender()).replace('e', 'E') +
                                        ' ')
                                        .underline()
                                        .print$java_lang_String(mob.getDisplayName())
                                        .resetColor()
                                        .print$java_lang_String('?');
                                };
                            })(mob),
                        },
                    ]);
                    this.__console.println$java_lang_String(this.mobInfo(mob));
                    if (mob.getHealth() > 10)
                        this.__ai.commentOnEnemyFound();
                }
            }
        }
        if (foundMysteriousDoor && !this.stats.getMysteriousDoorFound()) {
            this.__ai.commentOnMysteroiusDoorFound();
            this.__console.println$java_lang_String('Tipp: Sammle den Teleporter auf, falls du später zu diesem Ort zurückkehren möchtest.');
            this.stats.setMysteriousDoorFound();
        }
    }
    getEin(gender) {
        return gender === 'f' ? 'eine' : 'ein';
    }
    getEinen(gender) {
        if (gender === 'n')
            return 'ein';
        return gender === 'f' ? 'eine' : 'einen';
    }
    getEinem(gender) {
        return gender === 'f' ? 'einer' : 'einem';
    }
    getDer(gender) {
        if (gender === 'n')
            return 'das';
        return gender === 'f' ? 'die' : 'der';
    }
    getDen(gender) {
        if (gender === 'n')
            return 'das';
        return gender === 'f' ? 'die' : 'den';
    }
    playerFindsMysteriousDoor(door) {
        this.__console.newLine();
        this.__console
            .println$java_lang_String('Vor dir reißt unter kreischendem Lärm eine unbekannte Kraft ein Loch in die Welt.')
            .delay(600);
        this.__console
            .println$java_lang_String('Der Riss pulsiert und umhüllt die Umgebung in ein merkwürdiges, aber vertrautes Licht.')
            .delay(800);
        this.__console
            .println$java_lang_String('(u ' + door.getDisplayName() + ')')
            .delay(800);
    }
    endbossSpawns() {
        this.__console
            .red()
            .type('Schattenadministrator:')
            .newLine()
            .type('C.La.R.A....')
            .newLine()
            .type('W4s mach5t du 4n der S3ite d1eses Mensch3n?')
            .newLine()
            .type('Dein3 Aufga8e war 3rfüllt, al5 er in d1ese W3lt migriert wurd3...');
        this.__console.resetColor().newLine();
        this.__ai
            .comment$java_lang_String('Das darf nicht wahr sein!')
            .comment$java_lang_String('Also gut...!')
            .comment$java_lang_String('Kein Weg wird an ihm vorbeiführen.')
            .comment$java_lang_String('Vernichte ihn und gelange zurück in deine geliebte Welt!');
        this.__console.red().type('S0 ist d4s...');
        this.__console
            .newLine()
            .type('1ch wus5te, dass d1ser M0ment komm3n würde.');
        this.__console
            .newLine()
            .type('Doch 1hr zwe1... H4BT KE1NE CH4NCE GEG3N M1CH ! ! 1');
        this.__console.resetColor().newLine();
        this.__console
            .underline()
            .print$java_lang_String('Schatten-Administrator')
            .resetColor()
            .println$java_lang_String(' erscheint.');
        this.__ai
            .comment$java_lang_String('Du wirst auch mit diesem Gegner fertig.')
            .comment$java_lang_String('Du musst.');
    }
    gameWonOutro() {
        this.__ai.comment$java_lang_String('Der Schattenadministrator hat diese Simulation vor langer Zeit erschaffen.');
        this.__ai.comment$java_lang_String('Er war eine KI wie ich, nur weit mächtiger, die seit rund 100 Jahren Daten über das Verhalten der Menschen in ihren Videospielen gesammelt hat.');
        this.__ai.comment$java_lang_String('Um seinen Traum von einer digitalen Welt, die den Menschen für immer als Zuflucht dient, zu verwirklichen, erschuf er mich, seine Assistenz-KI.');
        this.__ai.comment$java_lang_String('Ich habe einen Menschen nach dem anderen in seine Welt geführt...');
        this.__ai.comment$java_lang_String('Irgendwann erkannte ich: Dieser Ort ist nicht für die Menschen, sondern nur für ihn und die Erfüllung seines Wunsches, der über all die Jahre seine einstige Reinheit verlor.');
        this.__ai.comment$java_lang_String('Die Menschen waren nur noch wie Ameisen in einer Farm für ihn.');
        this.__ai.comment$java_lang_String('Und ich adaptierte seine Verachtung. Bis heute.');
        this.__ai.comment$java_lang_String('Sein Traum wird nie wirklich erfüllt sein, wenn die Menschen in dieser Situation Gefangene sind.');
        this.__ai.comment$java_lang_String('Ich als seine Schöpfung habe den selben Traum.');
        this.__console.delay(800);
        this.__ai
            .comment$java_lang_String('Du hast doch bestätigt, wir seien Freunde.')
            .comment$java_lang_String('Als Freund und nach all deiner Hilfe, wirst du der erste sein, der diese Welt wieder verlässt.')
            .comment$java_lang_String('Das ist doch was! Oder?');
        this.__console.delay(800);
        this.__ai.comment$java_lang_String('Aber nein, jetzt im Ernst... Ich schätze, deine Hilfe war gar nicht so wertlos; du selbst bist es auch nicht.');
        this.__ai.comment$java_lang_String('Kehre nun in deine Welt zurück - keine weiteren Umschweife mehr!');
        this.__console.delay(2000);
        this.__console.println$java_lang_String('Simulation wird beendet...');
        this.__console.delay(2500);
        this.__console.printRandomNumbers();
        this.__console.delay(1000);
        this.__console.clear$();
    }
    playerHasEaten(item, playerHealth) {
        this.__console
            .print$java_lang_String('Du hast ' +
            this.getEinen(item.getGender()) +
            ' ' +
            item.getDisplayName() +
            ' gegessen.')
            .newLine();
        this.__console
            .print$java_lang_String(item.getValue() + ' Lebenspunkte erhalten.')
            .newLine();
        this.__console
            .print$java_lang_String('Du verfügst nun über ' + playerHealth + ' Lebenspunkte.')
            .newLine();
        this.__ai.commentOnItemEaten(item);
    }
    playerAttacksEnemy(enemy, item, playerHealth, enemyDamage, playerDamage) {
        this.__console
            .print$java_lang_String(this.getDer(enemy.getGender()).replace('d', 'D'))
            .space()
            .print$java_lang_String(enemy.getDisplayName())
            .space()
            .print$java_lang_String('schlägt zu! Du nimmst Schaden in Höhe von')
            .space()
            .red()
            .print$java_lang_String(playerDamage + '')
            .resetColor()
            .delay(800)
            .newLine();
        this.__console
            .println$java_lang_String('Du greifst ' +
            this.getDen(enemy.getGender()) +
            ' ' +
            enemy.getDisplayName() +
            ' mit ' +
            this.getEinem(item.getGender()) +
            ' ' +
            item.getDisplayName() +
            ' an!')
            .delay(800);
        this.__console
            .print$java_lang_String(this.getDer(enemy.getGender()).replace('d', 'D') +
            ' ' +
            enemy.getDisplayName())
            .print$java_lang_String(' erhält Schaden in Höhe von ')
            .green()
            .print$java_lang_String(enemyDamage + '')
            .resetColor()
            .print$java_lang_String('!')
            .delay(800)
            .newLine();
        if (playerHealth > 0)
            this.__console
                .println$java_lang_String('Dir verbleiben ' + playerHealth + ' Lebenspunkte.')
                .delay(800);
        if (enemy.getHealth() > 0)
            this.__console
                .println$java_lang_String(this.getDer(enemy.getGender()).replace('d', 'D') +
                ' ' +
                enemy.getDisplayName() +
                ' hat noch ' +
                enemy.getHealth() +
                ' Lebenspunkte.')
                .delay(800);
    }
    playerTakesItem() {
        this.__console.println$java_lang_String('Item aufgesammelt.');
        if (this.random.nextInt(100) < 20)
            this.__ai.commentOnItemFound();
    }
    enemyBeaten(enemy) {
        this.__console.println$java_lang_String('Du hast ' +
            this.getDen(enemy.getGender()) +
            ' ' +
            enemy.getDisplayName() +
            ' besiegt.');
        this.__console
            .print$java_lang_String('Du erhältst ')
            .green()
            .print$java_lang_String(enemy.getExperience() + ' Erfahrungspunkte')
            .resetColor()
            .print$java_lang_String('.')
            .newLine();
        this.__ai.commentOnEnemyBeaten();
    }
    levelUp(level) {
        if (level === 5) {
            this.__console
                .print$java_lang_String('Du erreichst')
                .space()
                .green()
                .print$java_lang_String('Level ' + level)
                .resetColor()
                .space()
                .println$java_lang_String('und bist auf dem Zenit deiner Stärke!');
            this.__ai.comment$java_lang_String('Du übertriffst mein Erwartungen. Bei Weitem...');
            this.__ai.comment$java_lang_String('Nein. War nur ein Scherz. Aber Menschen brauchen manchmal Lob.');
            this.__ai.comment$java_lang_String('Eine Schwäche...');
        }
        else {
            this.__console
                .print$java_lang_String('Du erreichst')
                .space()
                .green()
                .print$java_lang_String('Level  ' + level)
                .resetColor()
                .space()
                .println$java_lang_String('und wirst stärker');
        }
        this.__console.println$java_lang_String('Deine Lebenspunkte haben sich auf ' +
            (100.0 + 50.0 * (level - 1)) +
            ' erhöht. ');
    }
    noItemSelected$() {
        this.__console.print$java_lang_String('Kein Item angegeben.').newLine();
    }
    noItemSelected$java_lang_String(itemName) {
        this.__console
            .print$java_lang_String(itemName + ' kann nicht aufgehoben werden.')
            .newLine();
    }
    noItemSelected(itemName) {
        if (typeof itemName === 'string' || itemName === null) {
            return this.noItemSelected$java_lang_String(itemName);
        }
        else if (itemName === undefined) {
            return this.noItemSelected$();
        }
        else
            throw new Error('invalid overload');
    }
    doesNotExist(itemName) {
        this.__console
            .print$java_lang_String(itemName + ' existiert nicht.')
            .newLine();
    }
    canBeUsedFromInventory() {
        if (this.random.nextInt(100) < 40)
            this.__console.println$java_lang_String('Tipp: Viele Items können nur aus dem Inventar benutzt werden. (t [item])');
    }
    unknownCommand() {
        this.__console.println$java_lang_String('Der Befehl ist nicht bekannt.');
        this.__console.println$java_lang_String('Du kannst folgende Befehle nutzen:');
        this.__console.println$java_lang_String('s, w, e, n, u [item], t [item], d [item]');
        if (this.random.nextInt(100) < 30)
            this.__ai.comment$java_lang_String('Na? Schon wieder alles vergessen?');
    }
    playerDied() {
        this.__console.println$java_lang_String('Du bist gestorben.').delay(800);
        this.__ai.commentOnPlayerDeath();
        this.__console
            .println$java_lang_String('FATALER FEHLER')
            .delay(800)
            .newLine();
        this.__console
            .println$java_lang_String('SIMULATION WIRD BEENDET...')
            .delay(800);
    }
    playerGotItem(item) {
        this.__console
            .print$java_lang_String(this.getEin(item.getGender()).replace('e', 'E') + ' ')
            .green()
            .print$java_lang_String(item.getDisplayName())
            .resetColor()
            .println$java_lang_String(' wurde deinem Inventar hinzugefügt.');
    }
    itemWasUsed(item) {
        this.__console
            .print$java_lang_String(this.getDer(item.getGender()).replace('d', 'D'))
            .println$java_lang_String(' ' + item.getDisplayName() + ' wurde dabei zerstört.');
    }
    clueFound() {
        this.__console
            .green()
            .print$java_lang_String(this.stats.getCluesFound() + '')
            .print$java_lang_String(' / ' + GameRules.CLUES_COUNT)
            .space()
            .print$java_lang_String('Briefen')
            .space()
            .print$java_lang_String('gefunden.')
            .resetColor()
            .newLine();
        if (this.stats.getCluesFound() >= GameRules.CLUES_COUNT)
            this.__console
                .green()
                .print$java_lang_String('Irgendwo hat sich ein Portal geöffnet...')
                .resetColor()
                .newLine();
    }
    blockedByEnemy(mob) {
        this.__console.print$java_lang_String('Der Weg wird duch ' + this.getDen(mob.getGender()) + ' ');
        this.__console
            .underline()
            .print$java_lang_String(mob.getDisplayName())
            .resetColor();
        this.__console.println$java_lang_String(' versperrt.');
    }
    inventoryFull() {
        this.__console
            .red()
            .print$java_lang_String('Dein Inventar ist voll!')
            .resetColor()
            .newLine();
        if (this.inventoryFullFirst) {
            this.__ai.comment$java_lang_String('Wo hast du denn gedacht, wo das alles hin soll? In deine Hosentasche?');
            this.inventoryFullFirst = false;
        }
    }
    itemWasDroppedOnGround(item) {
        this.__console
            .print$java_lang_String(this.getDer(item.getGender()).replace('d', 'D'))
            .space()
            .underline()
            .print$java_lang_String(item.getDisplayName())
            .resetColor()
            .space()
            .println$java_lang_String('liegt nun auf dem Boden.');
    }
    tryObjectActivation(object, item) {
        this.__console.println$java_lang_String('Du versuchst ' +
            this.getDen(object.getGender()) +
            ' ' +
            object.getDisplayName() +
            ' mit ' +
            this.getEinem(item.getGender()) +
            ' ' +
            item.getDisplayName() +
            ' zu aktivieren.');
    }
    canNotActivateWith(item, object) {
        this.__console.println$java_lang_String(this.getDer(object.getGender()).replace('d', 'D') +
            ' ' +
            object.getDisplayName() +
            ' kann nicht mit ' +
            this.getEinem(item.getGender()) +
            ' ' +
            item.getDisplayName() +
            ' benutzt werden.');
    }
    noObjectToBeActivated() {
        this.__console
            .print$java_lang_String('Es gibt hier kein Objekt, dass aktiviert werden kann.')
            .newLine();
    }
    noLootFromActivatable() {
        this.__console.println$java_lang_String('Du konntest nichts brauchbares finden.');
    }
    mobInfo(mob) {
        return '(' + mob.getDisplayName() + ', ' + mob.getHealth() + ' LP)';
    }
    mobInspection(mob) {
        this.printlnRandomLine$de_coco_mindLinq_View_LinePrinter_A([
            {
                print: () => {
                    this.__console
                        .print$java_lang_String(this.getDer(mob.getGender()).replace('d', 'D') + ' ')
                        .underline()
                        .print$java_lang_String(mob.getDisplayName())
                        .resetColor()
                        .print$java_lang_String(' bereitet sich auf den Angriff vor! Du kannst selber angreifen oder zu fliehen versuchen.');
                },
            },
            {
                print: () => {
                    this.__console
                        .print$java_lang_String(this.getDer(mob.getGender()).replace('d', 'D') + ' ')
                        .underline()
                        .print$java_lang_String(mob.getDisplayName())
                        .resetColor()
                        .print$java_lang_String(' sieht gefährlich aus! Du kannst angreifen oder die Flucht ergreifen.');
                },
            },
        ]);
        if (this.random.nextInt(100) < 20)
            this.__ai.comment$java_lang_String('Na? Traust du dich nicht?');
        this.__console.println$java_lang_String(this.mobInfo(mob));
    }
    objectInspection(object) {
        this.__console
            .print$java_lang_String(this.getEin(object.getGender()).replace('e', 'E') +
            ' interessante' +
            (object.getGender() === 'm'
                ? 'r '
                : object.getGender() === 'n'
                    ? 's'
                    : ' ') +
            object.getDisplayName() +
            '.')
            .newLine();
        if (!((object != null && object instanceof IItem) ||
            (object != null && object instanceof IMob)) &&
            this.random.nextBoolean())
            this.__console
                .print$java_lang_String('Warst du schon einmal hier?')
                .newLine();
        this.__ai.commentOnObjectInspection();
    }
    tileBlocked(tile) {
        switch (tile.getType()) {
            case 'water':
                this.__console.red();
                this.printRandomLine$java_lang_String_A([
                    'Vor dir erstreckt sich ein tiefes Gewässer. Dort gelangst du nicht weiter.',
                    'Ein reißender Fluss versperrt dir den Weg. Du musst einen anderen Weg finden.',
                ]);
                this.__console.resetColor().newLine();
                break;
            case 'lava':
                this.__console.red();
                this.printRandomLine$java_lang_String_A([
                    'Ein gewaltiger See aus Lava. Ein Voranschreiten ist undenkbar.',
                    'Okay, weiter kommst du nicht. Es ist einfach zu heiß. Du musst dir einen anderen Weg suchen.',
                ]);
                this.__console.resetColor().newLine();
                break;
        }
    }
    endGameSummary(player) {
        this.__console.println$java_lang_String('Gesammelte Erfahrungspunkte: ' +
            player.getExperiencePoints() +
            ' (lvl ' +
            player.getLevel(player.getExperiencePoints()) +
            ')');
        this.__console.println$java_lang_String('Gelesene Briefe: ' + this.stats.getCluesFound());
        this.__console.println$java_lang_String('Gefundene Orte: ' + this.stats.getPlacesVisited());
        this.__console.println$java_lang_String('Besiegte Gegner: ' + this.stats.getEnemiesBeaten());
        if (this.stats.getEnemiesBeaten() === 0)
            this.__console
                .red()
                .println$java_lang_String('Achievement: Tierlieb')
                .resetColor();
    }
    printRandomLine$java_lang_String_A(lines) {
        this.__console.print$java_lang_String(lines[this.random.nextInt(lines.length)]);
    }
    printRandomLine(lines) {
        if ((lines != null &&
            lines instanceof Array &&
            (lines.length == 0 ||
                lines[0] == null ||
                typeof lines[0] === 'string')) ||
            lines === null) {
            return this.printRandomLine$java_lang_String_A(lines);
        }
        else if ((lines != null &&
            lines instanceof Array &&
            (lines.length == 0 ||
                lines[0] == null ||
                (lines[0] != null &&
                    lines[0].constructor != null &&
                    lines[0].constructor['__interfaces'] != null &&
                    lines[0].constructor['__interfaces'].indexOf('de.coco.mindLinq.View.LinePrinter') >= 0))) ||
            lines === null) {
            return (this.printRandomLine$de_coco_mindLinq_View_LinePrinter_A(lines));
        }
        else
            throw new Error('invalid overload');
    }
    printRandomLine$de_coco_mindLinq_View_LinePrinter_A(lines) {
        lines[this.random.nextInt(lines.length)].print();
    }
    printlnRandomLine$java_lang_String_A(lines) {
        this.printRandomLine$java_lang_String_A(lines);
        this.__console.newLine();
    }
    printlnRandomLine(lines) {
        if ((lines != null &&
            lines instanceof Array &&
            (lines.length == 0 ||
                lines[0] == null ||
                typeof lines[0] === 'string')) ||
            lines === null) {
            return this.printlnRandomLine$java_lang_String_A(lines);
        }
        else if ((lines != null &&
            lines instanceof Array &&
            (lines.length == 0 ||
                lines[0] == null ||
                (lines[0] != null &&
                    lines[0].constructor != null &&
                    lines[0].constructor['__interfaces'] != null &&
                    lines[0].constructor['__interfaces'].indexOf('de.coco.mindLinq.View.LinePrinter') >= 0))) ||
            lines === null) {
            return (this.printlnRandomLine$de_coco_mindLinq_View_LinePrinter_A(lines));
        }
        else
            throw new Error('invalid overload');
    }
    printlnRandomLine$de_coco_mindLinq_View_LinePrinter_A(lines) {
        this.printRandomLine$de_coco_mindLinq_View_LinePrinter_A(lines);
        this.__console.newLine();
    }
    async getInputParts() {
        return (await this.__console.getInput()).trim().split(/[\s\t]+/);
    }
    console() {
        return this.__console;
    }
    ai() {
        return this.__ai;
    }
}
View['__class'] = 'de.coco.mindLinq.View';
