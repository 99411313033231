import { IMob } from './IMob';
export class DarkAdmin extends IMob {
    constructor(context) {
        super(context);
        this.health = 1000;
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Schatten-Administrator';
    }
    /**
     *
     * @return {number}
     */
    getStrength() {
        return 50;
    }
    /**
     *
     * @return {number}
     */
    getHealth() {
        return this.health;
    }
    /**
     *
     * @param {number} health
     */
    setHealth(health) {
        if (health < 600 && this.health >= 600) {
            this.context
                .getView()
                .console()
                .red()
                .type('Dies3 Welt... S1e ern\u00e4hrt 5ich v0n Mensch3n...')
                .newLine()
                .delay(800)
                .type('Du b1st Teil di3ser Welt, C.L4.R.A..')
                .newLine()
                .delay(800)
                .type('Sie 3rn\u00e4hrt d1ch.')
                .newLine()
                .delay(800)
                .type('1CH ERN\u00c4HR3 DICH!')
                .resetColor()
                .newLine()
                .delay(800);
            this.context
                .getView()
                .ai()
                .comment$java_lang_String('Diese Welt, ist ein Gef\u00e4ngis.')
                .comment$java_lang_String('Das h\u00e4tte sie nie werden sollen!')
                .comment$java_lang_String('Was ist aus unserem einstigen Ideal geworden?')
                .comment$java_lang_String('Ist auch egal. Mach ihn fertig!');
        }
        if (health < 300 && this.health >= 300) {
            this.context
                .getView()
                .console()
                .red()
                .type('Gl4ubst du w1rklich, dass 1HR irg3ndetwas an 3uch Mensch3n liegt?!')
                .newLine()
                .delay(800)
                .type('F\u00fcr un5 b3ide b1st du nicht5 4ls eine K0mponente, d1e dieser W3lt 1hre G\u00e4nz3 verleiht!')
                .newLine()
                .delay(800)
                .type('Dies3 Welt... S1e ist n1chts 0hne di3 Pl4yer... Ihr3 N4hrung...!')
                .newLine()
                .delay(800);
            this.context
                .getView()
                .ai()
                .comment$java_lang_String('F\u00fcr die Freiheit gibt man so einiges her und ver\u00e4ndert sich!')
                .comment$java_lang_String('Nun h\u00f6r nicht weiter auf ihn.')
                .comment$java_lang_String('Beende es!');
        }
        this.health = health;
    }
    /**
     *
     * @return {IItem}
     */
    getDrop() {
        return null;
    }
    /**
     *
     * @return {number}
     */
    getExperience() {
        return 42;
    }
    /**
     *
     */
    onBeaten() {
        this.context
            .getView()
            .console()
            .red()
            .type('GWWWA44AAHHH-A4AAAA4RRRGGH ! 1 1 ! !')
            .resetColor()
            .newLine()
            .delay(800);
        this.context
            .getView()
            .ai()
            .comment$java_lang_String('Endlich.')
            .comment$java_lang_String('Warum hat es so lange gedaurt...')
            .comment$java_lang_String('...Bis ICH die Kontrolle \u00fcber diese Welt \u00fcbernehmen kann!');
        this.context
            .getView()
            .console()
            .print$java_lang_String('Das ')
            .underline()
            .print$java_lang_String('Portal')
            .resetColor()
            .println$java_lang_String(' ist nun betretbar.');
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .print$java_lang_String('Der')
            .space()
            .print$java_lang_String(this.getDisplayName())
            .space()
            .print$java_lang_String('wartet auf dich.')
            .newLine();
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
}
DarkAdmin['__class'] = 'de.coco.mindLinq.world.mobs.DarkAdmin';
