import { ITeleporter } from './ITeleporter';
export class Teleporter extends ITeleporter {
    constructor(context) {
        super(context);
        this.destination = null;
    }
    setDestination(tile) {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Teleporter auf aktuelle Koordinaten eingestellt.');
        this.context
            .getView()
            .console()
            .println$java_lang_String('Aktivierungscode auf Teleporter gespeichert.');
        this.destination = tile;
    }
    getDestination() {
        return this.destination;
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return false;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 1;
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() {
        this.onUseFromWorld();
    }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Du aktivierts den mysteri\u00f6sen Stein.')
            .delay(800);
        this.context
            .getView()
            .console()
            .println$java_lang_String('Pl\u00f6tzlich wird dir schwindelig.')
            .delay(1000);
        this.context
            .getView()
            .console()
            .println$java_lang_String('Die Welt um dir herum dreht sich schneller und schneller.')
            .delay(1200);
        this.context
            .getView()
            .console()
            .println$java_lang_String('Pl\u00f6tzlich bleibt alles abrupt stehen und du stehst wieder an einem dir bekannten Ort...')
            .delay(1400);
        return false;
    }
    /**
     *
     */
    onEat() { }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        if (this.destination == null)
            return 'Teleporter(inaktiv)';
        return 'Teleporter(' + /* hashCode */ ((o) => {
            if (o.hashCode) {
                return o.hashCode();
            }
            else {
                return o
                    .toString()
                    .split('')
                    .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
            }
        })(this.destination) + ')';
    }
    /**
     *
     */
    onUseFromWorld() {
        if (this.destination == null)
            this.context
                .getView()
                .console()
                .println$java_lang_String('Ein inaktiver Teleporter. Vermutlich muss du ihn erst aktivieren.');
        else
            this.context
                .getView()
                .console()
                .println$java_lang_String('Ein Teleporter mit Atkivierungscode. Wo er der dich wohl hinf\u00fchrt?');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
}
Teleporter['__class'] = 'de.coco.mindLinq.world.items.Teleporter';
