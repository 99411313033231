import { GameRules } from '../GameRules';
import { Apple } from './items/Apple';
import { Axe } from './items/Axe';
import { Bone } from './items/Bone';
import { FishingRod } from './items/FishingRod';
import { Flower } from './items/Flower';
import { Key } from './items/Key';
import { Letter } from './items/Letter';
import { Mushroom } from './items/Mushroom';
import { RawMeat } from './items/RawMeat';
import { Sandwich } from './items/Sandwich';
import { Stone } from './items/Stone';
import { Sushibox } from './items/Sushibox';
import { Teleporter } from './items/Teleporter';
import { Assaultrifle } from './items/weapons/Assaultrifle';
import { Branch } from './items/weapons/Branch';
import { Chainsaw } from './items/weapons/Chainsaw';
import { Crossbow } from './items/weapons/Crossbow';
import { Flamesword } from './items/weapons/Flamesword';
import { Hammer } from './items/weapons/Hammer';
import { Bear } from './mobs/Bear';
import { Boar } from './mobs/Boar';
import { GiantSandworm } from './mobs/GiantSandworm';
import { LavaGolem } from './mobs/LavaGolem';
import { Rabbit } from './mobs/Rabbit';
import { Yeti } from './mobs/Yeti';
import { Campfire } from './objects/Campfire';
import { Chest } from './objects/Chest';
import { Creek } from './objects/Creek';
import { House } from './objects/House';
import { MysteriousDoor } from './objects/MysteriousDoor';
import { Pond } from './objects/Pond';
import { Rock } from './objects/Rock';
import { Tree } from './objects/Tree';
import { TileTypes } from './TileTypes';
import { shuffle } from '@compactjs/shuffle';
import { SpawnTypes } from './SpawnTypes';
export class ObjectSpawner {
    constructor() {
        if (this.availableItems === undefined) {
            this.availableItems = null;
        }
        if (this.availableMobs === undefined) {
            this.availableMobs = null;
        }
        if (this.availableObjects === undefined) {
            this.availableObjects = null;
        }
        this.availableItems = {};
        this.availableMobs = {};
        this.availableObjects = {};
        this.initializeItems();
        this.initializeMobs();
        this.intializeObjects();
    }
    initializeItems() {
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Apple, new ObjectSpawner.SpawnRules(null, 20));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Sandwich, new ObjectSpawner.SpawnRules(null, 8));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Sushibox, new ObjectSpawner.SpawnRules(null, 5));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Mushroom, new ObjectSpawner.SpawnRules(null, 40));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, RawMeat, new ObjectSpawner.SpawnRules(/* singletonList */ [SpawnTypes.MOB], 100));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Key, new ObjectSpawner.SpawnRules(null, 20));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Stone, new ObjectSpawner.SpawnRules(null, 30));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Bone, new ObjectSpawner.SpawnRules(null, 20));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Letter, new ObjectSpawner.SpawnRules(null, 10));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Teleporter, new ObjectSpawner.SpawnRules(/* singletonList */ [SpawnTypes.CHEST], 90));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, FishingRod, new ObjectSpawner.SpawnRules(null, 15));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Flower, new ObjectSpawner.SpawnRules(
        /* asList */ [TileTypes.GRASS, TileTypes.FOREST, TileTypes.TUNDRA], 30));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Axe, new ObjectSpawner.SpawnRules(null, 70));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Branch, new ObjectSpawner.SpawnRules(
        /* asList */ [TileTypes.GRASS, TileTypes.FOREST], 40));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Hammer, new ObjectSpawner.SpawnRules(/* singletonList */ [TileTypes.FOREST], 30));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Flamesword, new ObjectSpawner.SpawnRules(/* singletonList */ [TileTypes.TUNDRA], 30));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Crossbow, new ObjectSpawner.SpawnRules(/* singletonList */ [TileTypes.DESERT], 30));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Chainsaw, new ObjectSpawner.SpawnRules(/* singletonList */ [SpawnTypes.CHEST], 90));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableItems, Assaultrifle, new ObjectSpawner.SpawnRules(/* singletonList */ [TileTypes.VOLCANIC], 10));
    }
    initializeMobs() {
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableMobs, Rabbit, new ObjectSpawner.SpawnRules(null, 28));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableMobs, Boar, new ObjectSpawner.SpawnRules(/* singletonList */ [TileTypes.GRASS], 38));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableMobs, Bear, new ObjectSpawner.SpawnRules(/* singletonList */ [TileTypes.FOREST], 36));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableMobs, Yeti, new ObjectSpawner.SpawnRules(/* singletonList */ [TileTypes.TUNDRA], 34));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableMobs, GiantSandworm, new ObjectSpawner.SpawnRules(/* singletonList */ [TileTypes.DESERT], 32));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableMobs, LavaGolem, new ObjectSpawner.SpawnRules(/* singletonList */ [TileTypes.VOLCANIC], 30));
    }
    intializeObjects() {
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableObjects, Chest, new ObjectSpawner.SpawnRules(null, 35));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableObjects, House, new ObjectSpawner.SpawnRules(null, 35));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableObjects, Campfire, new ObjectSpawner.SpawnRules(null, 80));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableObjects, Pond, new ObjectSpawner.SpawnRules(
        /* asList */ [TileTypes.GRASS, TileTypes.FOREST, TileTypes.TUNDRA], 70));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableObjects, Rock, new ObjectSpawner.SpawnRules(null, 100));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableObjects, Tree, new ObjectSpawner.SpawnRules(
        /* asList */ [
            TileTypes.GRASS,
            TileTypes.FOREST,
            TileTypes.TUNDRA,
            TileTypes.DESERT,
        ], 100));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableObjects, Creek, new ObjectSpawner.SpawnRules(
        /* asList */ [TileTypes.GRASS, TileTypes.FOREST, TileTypes.TUNDRA], 70));
        /* put */ ((m, k, v) => {
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                if ((m.entries[i].key == null && k == null) ||
                    (m.entries[i].key.equals != null && m.entries[i].key.equals(k)) ||
                    m.entries[i].key === k) {
                    m.entries[i].value = v;
                    return;
                }
            m.entries.push({
                key: k,
                value: v,
                getKey: function () {
                    return this.key;
                },
                getValue: function () {
                    return this.value;
                },
            });
        })(this.availableObjects, MysteriousDoor, new ObjectSpawner.SpawnRules(null, GameRules.CHANCE_FOR_WORLD_END));
    }
    static getInstance() {
        if (ObjectSpawner.instance == null)
            ObjectSpawner.instance = new ObjectSpawner();
        return ObjectSpawner.instance;
    }
    newItem(context, place) {
        let classes = ((m) => {
            let r = [];
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                r.push(m.entries[i].key);
            return r;
        })(this.availableItems).slice(0);
        classes = shuffle(classes);
        const luckyNumber = context.getRandom().nextInt(100);
        for (let index124 = 0; index124 < classes.length; index124++) {
            let cls = classes[index124];
            {
                if (cls === Letter &&
                    context.getStats().getCluesFound() >= GameRules.CLUES_COUNT)
                    continue;
                if (place != null &&
                    /* get */ ((m, k) => {
                        if (m.entries == null)
                            m.entries = [];
                        for (let i = 0; i < m.entries.length; i++)
                            if ((m.entries[i].key == null && k == null) ||
                                (m.entries[i].key.equals != null &&
                                    m.entries[i].key.equals(k)) ||
                                m.entries[i].key === k) {
                                return m.entries[i].value;
                            }
                        return null;
                    })(this.availableItems, cls).notMatchesBiomes(place))
                    continue;
                if (luckyNumber <
                    /* get */ ((m, k) => {
                        if (m.entries == null)
                            m.entries = [];
                        for (let i = 0; i < m.entries.length; i++)
                            if ((m.entries[i].key == null && k == null) ||
                                (m.entries[i].key.equals != null &&
                                    m.entries[i].key.equals(k)) ||
                                m.entries[i].key === k) {
                                return m.entries[i].value;
                            }
                        return null;
                    })(this.availableItems, cls).getChance()) {
                    try {
                        const tmpClass = cls;
                        return new tmpClass(context);
                        // const constructor: Constructor<IItem> = tmpClass.getDeclaredConstructor(GameContext);
                        // return constructor.newInstance(context);
                    }
                    catch (ex) {
                        console.error(ex.message, ex);
                    }
                }
                break;
            }
        }
        return null;
    }
    newMob(context, place) {
        let classes = ((m) => {
            let r = [];
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                r.push(m.entries[i].key);
            return r;
        })(this.availableMobs).slice(0);
        classes = shuffle(classes);
        const luckyNumber = context.getRandom().nextInt(100);
        for (let index125 = 0; index125 < classes.length; index125++) {
            let cls = classes[index125];
            {
                if (place != null &&
                    /* get */ ((m, k) => {
                        if (m.entries == null)
                            m.entries = [];
                        for (let i = 0; i < m.entries.length; i++)
                            if ((m.entries[i].key == null && k == null) ||
                                (m.entries[i].key.equals != null &&
                                    m.entries[i].key.equals(k)) ||
                                m.entries[i].key === k) {
                                return m.entries[i].value;
                            }
                        return null;
                    })(this.availableMobs, cls).notMatchesBiomes(place))
                    continue;
                if (luckyNumber <
                    /* get */ ((m, k) => {
                        if (m.entries == null)
                            m.entries = [];
                        for (let i = 0; i < m.entries.length; i++)
                            if ((m.entries[i].key == null && k == null) ||
                                (m.entries[i].key.equals != null &&
                                    m.entries[i].key.equals(k)) ||
                                m.entries[i].key === k) {
                                return m.entries[i].value;
                            }
                        return null;
                    })(this.availableMobs, cls).getChance()) {
                    try {
                        const tmpClass = cls;
                        return new tmpClass(context);
                        // const constructor: Constructor<IMob> = tmpClass.getDeclaredConstructor(
                        // 	GameContext
                        // );
                        // return constructor.newInstance(context);
                    }
                    catch (ex) {
                        console.error(ex.message, ex);
                    }
                }
                break;
            }
        }
        return null;
    }
    newWorldObject(context, place) {
        let classes = ((m) => {
            let r = [];
            if (m.entries == null)
                m.entries = [];
            for (let i = 0; i < m.entries.length; i++)
                r.push(m.entries[i].key);
            return r;
        })(this.availableObjects).slice(0);
        classes = shuffle(classes);
        // Collections.shuffle(classes);
        const luckyNumber = context.getRandom().nextInt(100);
        for (let index126 = 0; index126 < classes.length; index126++) {
            let cls = classes[index126];
            {
                if (cls === MysteriousDoor &&
                    (context.getStats().getPlacesVisited() <
                        GameRules.PLACES_VISITED_BEFORE_WORLD_END ||
                        context.getStats().getCluesFound() < GameRules.CLUES_COUNT ||
                        context.getStats().getMysteriousDoorFound()))
                    continue;
                if (place != null &&
                    /* get */ ((m, k) => {
                        if (m.entries == null)
                            m.entries = [];
                        for (let i = 0; i < m.entries.length; i++)
                            if ((m.entries[i].key == null && k == null) ||
                                (m.entries[i].key.equals != null &&
                                    m.entries[i].key.equals(k)) ||
                                m.entries[i].key === k) {
                                return m.entries[i].value;
                            }
                        return null;
                    })(this.availableObjects, cls).notMatchesBiomes(place))
                    continue;
                if (luckyNumber <
                    /* get */ ((m, k) => {
                        if (m.entries == null)
                            m.entries = [];
                        for (let i = 0; i < m.entries.length; i++)
                            if ((m.entries[i].key == null && k == null) ||
                                (m.entries[i].key.equals != null &&
                                    m.entries[i].key.equals(k)) ||
                                m.entries[i].key === k) {
                                return m.entries[i].value;
                            }
                        return null;
                    })(this.availableObjects, cls).getChance()) {
                    try {
                        const tmpClass = cls;
                        return new tmpClass(context);
                        // const constructor: Constructor<IWorldObject> = tmpClass.getDeclaredConstructor(
                        // 	GameContext
                        // );
                        // return constructor.newInstance(context);
                    }
                    catch (ex) {
                        console.error(ex.message, ex);
                    }
                }
                break;
            }
        }
        return null;
    }
}
ObjectSpawner.instance = null;
ObjectSpawner['__class'] = 'de.coco.mindLinq.world.ObjectSpawner';
(function (ObjectSpawner) {
    class SpawnRules {
        constructor(biomes, spawnChance) {
            if (this.biomes === undefined) {
                this.biomes = null;
            }
            if (this.chance === undefined) {
                this.chance = 0;
            }
            this.biomes = biomes;
            this.chance = spawnChance;
        }
        notMatchesBiomes(biome) {
            return (this.biomes != null &&
                !( /* contains */(this.biomes.indexOf(biome) >= 0)));
        }
        getChance() {
            return this.chance;
        }
    }
    ObjectSpawner.SpawnRules = SpawnRules;
    SpawnRules['__class'] = 'de.coco.mindLinq.world.ObjectSpawner.SpawnRules';
})(ObjectSpawner || (ObjectSpawner = {}));
