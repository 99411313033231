export var Output;
(function (Output) {
    let Style;
    (function (Style) {
        Style["RESET"] = "RESET";
        Style["RED"] = "RED";
        Style["GREEN"] = "GREEN";
        Style["YELLOW"] = "YELLOW";
        Style["UNDERLINED"] = "UNDERLINED";
    })(Style = Output.Style || (Output.Style = {}));
})(Output || (Output = {}));
