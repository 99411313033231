/* Generated from Java with JSweet 3.0.0 - http://www.jsweet.org */
import { GameContext } from '../GameContext';
import { GameRules } from '../GameRules';
import { Teleporter } from './items/Teleporter';
import { MysteriousDoor } from './objects/MysteriousDoor';
import { ObjectSpawner } from './ObjectSpawner';
import { TileTypes } from './TileTypes';
export class Tile {
    constructor(context, previousTile, direction) {
        this.visits = 0;
        if (((context != null && context instanceof GameContext) ||
            context === null) &&
            ((previousTile != null && previousTile instanceof Tile) ||
                previousTile === null) &&
            (typeof direction === 'string' || direction === null)) {
            let __args = arguments;
            if (this.items === undefined) {
                this.items = null;
            }
            if (this.mobs === undefined) {
                this.mobs = null;
            }
            if (this.objects === undefined) {
                this.objects = null;
            }
            if (this.tileSouth === undefined) {
                this.tileSouth = null;
            }
            if (this.tileNorth === undefined) {
                this.tileNorth = null;
            }
            if (this.tileWest === undefined) {
                this.tileWest = null;
            }
            if (this.tileEast === undefined) {
                this.tileEast = null;
            }
            if (this.accessible === undefined) {
                this.accessible = false;
            }
            if (this.type === undefined) {
                this.type = null;
            }
            if (this.context === undefined) {
                this.context = null;
            }
            this.context = context;
            switch (direction) {
                case 's':
                    this.tileSouth = previousTile;
                    break;
                case 'n':
                    this.tileNorth = previousTile;
                    break;
                case 'w':
                    this.tileWest = previousTile;
                    break;
                case 'e':
                    this.tileEast = previousTile;
                    break;
            }
            this.generateProperties(previousTile);
        }
        else if (((context != null && context instanceof GameContext) ||
            context === null) &&
            previousTile === undefined &&
            direction === undefined) {
            let __args = arguments;
            if (this.items === undefined) {
                this.items = null;
            }
            if (this.mobs === undefined) {
                this.mobs = null;
            }
            if (this.objects === undefined) {
                this.objects = null;
            }
            if (this.tileSouth === undefined) {
                this.tileSouth = null;
            }
            if (this.tileNorth === undefined) {
                this.tileNorth = null;
            }
            if (this.tileWest === undefined) {
                this.tileWest = null;
            }
            if (this.tileEast === undefined) {
                this.tileEast = null;
            }
            if (this.accessible === undefined) {
                this.accessible = false;
            }
            if (this.type === undefined) {
                this.type = null;
            }
            if (this.context === undefined) {
                this.context = null;
            }
            this.context = context;
            this.generateProperties(null);
        }
        else
            throw new Error('invalid overload');
    }
    generateProperties(previousTile) {
        this.context.getStats().incrementPlacesVisited();
        this.accessible = true;
        if (previousTile == null) {
            this.type = TileTypes.GRASS;
        }
        else {
            const luckyNumber = this.context.getRandom().nextInt(100);
            switch (previousTile.getType()) {
                case TileTypes.GRASS:
                    if (luckyNumber < 15)
                        this.type = TileTypes.DESERT;
                    else if (luckyNumber < 25)
                        this.type = TileTypes.TUNDRA;
                    else if (luckyNumber < 45)
                        this.type = TileTypes.FOREST;
                    else if (luckyNumber < 95)
                        this.type = TileTypes.GRASS;
                    else {
                        this.type = TileTypes.WATER;
                        this.accessible = false;
                    }
                    break;
                case TileTypes.FOREST:
                    if (luckyNumber < 50)
                        this.type = TileTypes.FOREST;
                    else if (luckyNumber < 70)
                        this.type = TileTypes.TUNDRA;
                    else if (luckyNumber < 90)
                        this.type = TileTypes.GRASS;
                    else {
                        this.type = TileTypes.WATER;
                        this.accessible = false;
                    }
                    break;
                case TileTypes.TUNDRA:
                    if (luckyNumber < 50)
                        this.type = TileTypes.TUNDRA;
                    else if (luckyNumber < 75)
                        this.type = TileTypes.FOREST;
                    else
                        this.type = TileTypes.GRASS;
                    break;
                case TileTypes.DESERT:
                    if (luckyNumber < 60)
                        this.type = TileTypes.DESERT;
                    else if (luckyNumber < 80)
                        this.type = TileTypes.VOLCANIC;
                    else
                        this.type = TileTypes.GRASS;
                    break;
                case TileTypes.VOLCANIC:
                    if (luckyNumber < 70)
                        this.type = TileTypes.VOLCANIC;
                    else if (luckyNumber < 90)
                        this.type = TileTypes.DESERT;
                    else {
                        this.type = TileTypes.LAVA;
                        this.accessible = false;
                    }
                    break;
            }
        }
        if (!this.accessible)
            return;
        this.items = [];
        for (let i = 0; i < GameRules.MAX_ITEMS_PER_TILE; i++) {
            {
                do {
                    {
                        const item = ObjectSpawner.getInstance().newItem(this.context, this.getType());
                        if (item != null &&
                            !this.items.some(((item) => {
                                return (tItem) => 
                                /* equals */ (((o1, o2) => o1 && o1.equals ? o1.equals(o2) : o1 === o2)(tItem.constructor, item.constructor));
                            })(item)))
                            /* add */ this.items.push(item) > 0;
                    }
                } while (
                /* size */ this.items.length < GameRules.MIN_ITEMS_PER_TILE);
            }
        }
        this.mobs = [];
        if (previousTile != null) {
            for (let i = 0; i < GameRules.MAX_ENEMY_PER_TILE; i++) {
                {
                    do {
                        {
                            const mob = ObjectSpawner.getInstance().newMob(this.context, this.getType());
                            if (mob != null &&
                                !this.mobs.some(((mob) => {
                                    return (tMob) => 
                                    /* equals */ (((o1, o2) => o1 && o1.equals ? o1.equals(o2) : o1 === o2)(tMob.constructor, mob.constructor));
                                })(mob)))
                                /* add */ this.mobs.push(mob) > 0;
                        }
                    } while (
                    /* size */ this.mobs.length < GameRules.MIN_ENEMY_PER_TILE);
                }
            }
        }
        this.objects = [];
        for (let i = 0; i < GameRules.MAX_WORLD_OBJECTS_PER_TILE; i++) {
            {
                do {
                    {
                        const object = ObjectSpawner.getInstance().newWorldObject(this.context, this.getType());
                        if (object != null &&
                            !this.objects.some(((object) => {
                                return (tObj) => 
                                /* equals */ (((o1, o2) => o1 && o1.equals ? o1.equals(o2) : o1 === o2)(tObj.constructor, object.constructor));
                            })(object))) {
                            if (object != null && object instanceof MysteriousDoor) {
                                const teleporter = new Teleporter(this.context);
                                teleporter.setDestination(this);
                                /* add */ this.items.push(teleporter) > 0;
                            }
                            /* add */ this.objects.push(object) > 0;
                        }
                    }
                } while (
                /* size */ this.objects.length <
                    GameRules.MIN_WORLD_OBJECTS_PER_TILE);
            }
        }
    }
    getEnemy() {
        if ( /* size */this.mobs.length > 0)
            return /* get */ this.mobs[0];
        return null;
    }
    hasEnemy() {
        return this.getEnemy() != null;
    }
    getType() {
        return this.type;
    }
    isAccessible() {
        return this.accessible;
    }
    getMobs() {
        return this.mobs;
    }
    getMob(name) {
        for (let index121 = 0; index121 < this.mobs.length; index121++) {
            let mob = this.mobs[index121];
            if (mob.getDisplayName().toLowerCase() === name.toLowerCase())
                return mob;
        }
        return null;
    }
    addMob(mob) {
        /* add */ this.mobs.push(mob) > 0;
    }
    removeMob(mob) {
        /* remove */ ((a) => {
            let index = a.indexOf(mob);
            if (index >= 0) {
                a.splice(index, 1);
                return true;
            }
            else {
                return false;
            }
        })(this.mobs);
    }
    getItems() {
        return this.items;
    }
    getItem(name) {
        for (let index122 = 0; index122 < this.items.length; index122++) {
            let item = this.items[index122];
            if (item.getDisplayName().toLowerCase() === name.toLowerCase())
                return item;
        }
        return null;
    }
    addItem(item) {
        /* add */ this.items.push(item) > 0;
    }
    removeItem(item) {
        /* remove */ ((a) => {
            let index = a.indexOf(item);
            if (index >= 0) {
                a.splice(index, 1);
                return true;
            }
            else {
                return false;
            }
        })(this.items);
    }
    getObjects() {
        return this.objects;
    }
    getObject(name) {
        for (let index123 = 0; index123 < this.objects.length; index123++) {
            let object = this.objects[index123];
            if (object.getDisplayName().toLowerCase() === name.toLowerCase())
                return object;
        }
        return null;
    }
    addObject(object) {
        /* add */ this.objects.push(object) > 0;
    }
    removeObject(object) {
        /* remove */ ((a) => {
            let index = a.indexOf(object);
            if (index >= 0) {
                a.splice(index, 1);
                return true;
            }
            else {
                return false;
            }
        })(this.objects);
    }
    getTileSouth() {
        if (this.tileSouth == null)
            this.tileSouth = new Tile(this.context, this, 'n');
        return this.tileSouth;
    }
    getTileNorth() {
        if (this.tileNorth == null)
            this.tileNorth = new Tile(this.context, this, 's');
        return this.tileNorth;
    }
    getTileWest() {
        if (this.tileWest == null)
            this.tileWest = new Tile(this.context, this, 'e');
        return this.tileWest;
    }
    getTileEast() {
        if (this.tileEast == null)
            this.tileEast = new Tile(this.context, this, 'w');
        return this.tileEast;
    }
    enter() {
        this.visits++;
    }
    getVisits() {
        return this.visits;
    }
}
Tile['__class'] = 'de.coco.mindLinq.world.Tile';
