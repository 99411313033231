<script lang="ts">
	import { observe } from '@compactjs/chatscroll';
	import { onMount } from 'svelte';

	export let turnedOn: boolean = false;
	export let height: number | undefined = undefined;
	export let width: number | undefined = undefined;
	export let disablePadding: boolean = false;

	let style = '';
	$: {
		style = '';
		if (width) style += ` --width: ${width}px;`;
		if (height) style += ` --height: ${height}px;`;
		// make scanlines appear a little more random
		style += `--scanline-delay: ${Math.random() * 8}s`;
	}

	let contentElement: HTMLElement;
	onMount(() => {
		observe(contentElement);
	});
</script>

<div class:screen-wrapper={!disablePadding} {style}>
	<div class="screen" class:turnedOn>
		<div class="crt">
			<div class="reflection" />
			<div class="scanline" />
			<div class="content" bind:this={contentElement}>
				<slot />
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	@import './screen-flicker.scss';
	@import '../../styles/filter.scss';

	.screen-wrapper {
		padding: 15px;
	}
	.screen {
		display: inline-block;
		width: var(--width, 100%);
		height: var(--height, 100%);
		position: relative;
		box-shadow: 0 0 70px 20px #cae9db42;
		box-sizing: border-box;
		overflow: hidden;
		border-radius: 15px;
		box-shadow: 0 0 3px 14px rgba(0, 0, 0, 0.295);

		&::after {
			@include overlay;
			border-radius: 15px;
			box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.897) inset;
		}

		.content {
			opacity: 0;
			height: 100%;
			width: 100%;
			overflow: hidden;
		}
		&.turnedOn {
			.content {
				opacity: 1;
				animation: flicker 1s;
			}
			.scanline {
				opacity: 0.1;
			}
			.crt:before {
				@include overlay;
				display: inline-block;
				background: linear-gradient(
						rgba(18, 16, 16, 0) 50%,
						rgba(0, 0, 0, 0.25) 50%
					),
					linear-gradient(
						90deg,
						rgba(255, 0, 0, 0.06),
						rgba(0, 255, 0, 0.02),
						rgba(0, 0, 255, 0.06)
					);
				background-size: 100% 3px, 5px 100%;
				z-index: 2;
				pointer-events: none;
				animation: flicker 0.15s infinite;
			}
		}

		.crt {
			position: relative;
			width: 100%;
			height: 100%;
		}

		.crt:after {
			@include overlay;
			display: inline-block;
			background: #05321e;
			background-image: radial-gradient(ellipse, #109257 0%, #000000ad 95%);
			opacity: 0.4;
			z-index: 3;
			pointer-events: none;
		}

		.scanline {
			opacity: 0;
			width: 100%;
			height: 100px;
			z-index: 8;
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(255, 255, 255, 0.5) 10%,
				rgba(0, 0, 0, 0.02) 100%
			);
			position: absolute;
			bottom: 100%;
			pointer-events: none;
			animation: scanline 8s var(--scanline-delay, 0s) linear infinite;
			@keyframes scanline {
				0% {
					bottom: 100%;
				}
				80% {
					bottom: 100%;
				}
				100% {
					bottom: -20%;
				}
			}
		}

		.reflection {
			pointer-events: none;
			position: absolute;
			background-image: radial-gradient(ellipse, #fffdeb 0%, #00000000 50%);
			top: -50%;
			left: -40%;
			width: 150%;
			height: 150%;
			opacity: 0.5;
		}
	}
</style>
