<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();

	let inputElement: HTMLElement;
	export let editable: boolean = true;
	export let autofocus: boolean = null;
	export let multiInput: boolean = false;
	export let suggestions: string[] = [];
	export let catchKeyboard: boolean = true;
	export let alwaysKeepFocused: boolean = false;

	export function focus() {
		inputElement.focus();
	}

	const history: string[] = [];
	let historyIndex = 0;
	let autocompleteIndex = 0;
	let autocompletedString = '';

	const onKeydown = (event: KeyboardEvent) => {
		if (event.keyCode === 13) {
			history.push(inputElement.textContent);
			dispatch('input', inputElement.textContent);
			if (!multiInput) {
				editable = false;
			} else {
				inputElement.innerHTML = '';
			}
			historyIndex = 0;
			event.preventDefault();
		} else if (event.keyCode === 38) {
			if (historyIndex < history.length) {
				inputElement.innerHTML = history[history.length - 1 - historyIndex++];
				moveCarretToEnd(inputElement);
				event.preventDefault();
			}
		} else if (event.keyCode === 40) {
			if (historyIndex > 0) {
				historyIndex--;
				inputElement.innerHTML = history[history.length - 1 - historyIndex];
				moveCarretToEnd(inputElement);
				event.preventDefault();
			}
		}
		if (event.keyCode === 67 && event.ctrlKey) {
			inputElement.innerHTML = '';
		}
		if (event.keyCode === 9) {
			const inputText = autocompletedString
				? inputElement.textContent.slice(0, -autocompletedString.length)
				: inputElement.textContent;
			const suggestion = autocomplete(inputText, autocompleteIndex);
			if (suggestion) {
				autocompletedString = suggestion.slice(inputText.length);
				autocompleteIndex++;
				inputElement.innerHTML = suggestion;
				moveCarretToEnd(inputElement);
			} else {
				autocompleteIndex = 0;
			}
			event.preventDefault();
		} else {
			autocompleteIndex = 0;
			autocompletedString = '';
		}
		if (catchKeyboard) event.stopImmediatePropagation();
	};

	// https://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity?rq=1
	const moveCarretToEnd = (element: Node) => {
		const range = document.createRange();
		range.selectNodeContents(element);
		range.collapse(false);
		const selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
	};

	const autocomplete = (partial: string, start: number): string | null => {
		return (
			suggestions.filter((s) => s.startsWith(partial.toLowerCase().trim()))[
				start
			] || null
		);
	};
</script>

<!-- svelte-ignore a11y-autofocus -->
<div class="wrapper">
	{#if editable}
		<span>{'> '}</span>
	{/if}
	<div
		on:blur={() => {
			if (alwaysKeepFocused) inputElement.focus();
		}}
		spellcheck="false"
		class="input"
		contenteditable={editable}
		on:keydown={onKeydown}
		bind:this={inputElement}
		{autofocus}
	/>
</div>

<style lang="scss">
	.wrapper {
		display: flex;
		.input {
			width: 100%;
			outline: none;
		}
	}
</style>
