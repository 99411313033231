import { IItem } from './IItem';
export class Apple extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return true;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 40;
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Apfel';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Ein Apfel ist gesund und saftig!');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
    /**
     *
     */
    onDrop() {
        if (this.context.getRandom().nextBoolean())
            this.context
                .getView()
                .ai()
                .comment$java_lang_String('An apple a day keeps the doctor away. Und du schmei\u00dft den einfach weg??');
    }
    /**
     *
     */
    onTake() { }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return true;
    }
    /**
     *
     */
    onEat() { }
}
Apple['__class'] = 'de.coco.mindLinq.world.items.Apple';
