import { IItem } from './IItem';
export class FishingRod extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return false;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return true;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 5;
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() { }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return this.context.getRandom().nextBoolean();
    }
    /**
     *
     */
    onEat() { }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Angel';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Eine Angel. Wof\u00fcr die wohl gut ist?');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'f';
    }
}
FishingRod['__class'] = 'de.coco.mindLinq.world.items.FishingRod';
