import { GameRules } from '../../GameRules';
import { IItem } from './IItem';
export class Letter extends IItem {
    constructor(context) {
        super(context);
        this.letterNumber = 0;
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return false;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 0;
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() { }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        if (this.letterNumber === 0) {
            this.letterNumber = this.context.getStats().getCluesFound() + 1;
            if (this.context.getStats().getCluesFound() < GameRules.CLUES_COUNT)
                this.context.getStats().incrementCluesFound();
        }
        this.context
            .getView()
            .console()
            .println$java_lang_String('Auf dem Brief wurde mit Handschrift geschrieben:');
        switch (this.letterNumber) {
            case 1:
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('"Wer auch immer das hier liest.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Du musst hier weg!');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Diese Welt ist nicht real.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Finde einen Weg hier raus.');
                this.context.getView().console().println$java_lang_String('Thomas"');
                break;
            case 2:
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('"The cake is a lie."');
                break;
            case 3:
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('"Franz ist entlaufen');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Wer auch immer das hier liest:');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Franz ist seit Tagen verschwunden.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Ihn hat das Ganze hier sehr mitgenommen.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Er ist verwirrt.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Vielleicht findest du ihn ja.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Kannst du ihm helfen?');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Er glaubt er sei ein Huhn."');
                break;
            case 4:
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('"Dienstag 23. Oktober 2097');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Tagelang irre ich jetzt schon hier herum.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Ich wei\u00df schon gar nicht mehr wonach ich suche.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Oder woher ich komme.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Wenn ich genau nachdenke, kenne ich noch nicht mal mehr meinen Namen.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Ich habe seit einiger Zeit das Gef\u00fchl, ich bewege mich im Kreis.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Aber ich muss weiter. Immer weiter.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Immer weiter zum Ziel.');
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('Irgendwo gibt es ein Ziel. Es muss eins geben."');
                break;
            default:
                this.context
                    .getView()
                    .console()
                    .println$java_lang_String('.zz.z.zkr..r')
                    .println$java_lang_String('.z.zh.schhh.. Finde.. zzs')
                    .println$java_lang_String('ss..z das .zz Portal..!');
                break;
        }
        this.context.getView().clueFound();
        return true;
    }
    /**
     *
     */
    onEat() { }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Brief';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context.getView().objectInspection(this);
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
}
Letter['__class'] = 'de.coco.mindLinq.world.items.Letter';
