import { Axe } from '../items/Axe';
import { Stone } from '../items/Stone';
import { IActivatable } from './IActivatable';
export class Rock extends IActivatable {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Felsen';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context.getView().objectInspection(this);
    }
    activate(item) {
        if (item != null && item instanceof Axe) {
            this.context
                .getView()
                .console()
                .println$java_lang_String('Du klopfst mit der Axt gegen den Felsen.')
                .delay(500);
            return true;
        }
        return false;
    }
    getLoot() {
        return new Stone(this.context);
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
}
Rock['__class'] = 'de.coco.mindLinq.world.objects.Rock';
