/* Generated from Java with JSweet 3.0.0 - http://www.jsweet.org */
import { Output } from './Output';
export class IOController {
    constructor(input, output) {
        this.task = new Promise((resolve) => {
            resolve();
        });
        if (this.input === undefined) {
            this.input = null;
        }
        if (this.output === undefined) {
            this.output = null;
        }
        if (this.random === undefined) {
            this.random = null;
        }
        this.input = input;
        this.output = output;
    }
    schedule(task) {
        const currentTask = this.task;
        this.task = (async () => {
            await currentTask;
            await task();
        })();
    }
    type(str) {
        this.delay(400);
        for (let i = 0; i < str.length; i++) {
            {
                this.print$java_lang_String$int(str.charAt(i) + '', 20);
            }
        }
        this.delay(str.length * 35);
        return this;
    }
    printRandomNumbers() {
        for (let i = 0; i < 70; i++) {
            {
                for (let j = 0; j < Math.round(Math.random() * 300); j++) {
                    {
                        this.print$java_lang_String((Math.random() > 0.5 ? 0 : 1) + '');
                    }
                }
                this.delay(50);
                this.newLine();
            }
        }
    }
    loading() {
        for (let i = 0; i < 4; i++) {
            {
                this.print$java_lang_String$int('.', 1000);
            }
        }
    }
    async getInput() {
        let receivedInput;
        await this.task;
        await new Promise((resolve) => {
            this.input.onInput({
                input(input) {
                    receivedInput = input;
                    resolve();
                },
            });
        });
        return receivedInput;
    }
    print$java_lang_String(str) {
        return this.print$java_lang_String$int(str, 0);
    }
    print$java_lang_String$int(str, delay) {
        this.delay(delay);
        this.schedule(() => {
            this.output.print(str);
        });
        return this;
    }
    print(str, delay) {
        if ((typeof str === 'string' || str === null) &&
            (typeof delay === 'number' || delay === null)) {
            return this.print$java_lang_String$int(str, delay);
        }
        else if ((typeof str === 'string' || str === null) &&
            delay === undefined) {
            return this.print$java_lang_String(str);
        }
        else
            throw new Error('invalid overload');
    }
    println$java_lang_String(str) {
        return this.print$java_lang_String(str).newLine();
    }
    println$java_lang_String$int(str, delay) {
        return this.print$java_lang_String$int(str, delay).newLine();
    }
    println(str, delay) {
        if ((typeof str === 'string' || str === null) &&
            (typeof delay === 'number' || delay === null)) {
            return this.println$java_lang_String$int(str, delay);
        }
        else if ((typeof str === 'string' || str === null) &&
            delay === undefined) {
            return this.println$java_lang_String(str);
        }
        else
            throw new Error('invalid overload');
    }
    delay(delay) {
        this.schedule(async () => {
            await new Promise((resolve) => {
                setTimeout(resolve, delay);
            });
        });
        return this;
    }
    newLine() {
        this.schedule(() => {
            this.output.newLine();
        });
        return this;
    }
    clearLine() {
        this.schedule(() => {
            this.output.clearLine();
        });
        return this;
    }
    clear$() {
        return this.clear$int(100);
    }
    clear$int(number) {
        for (let i = 0; i < number; i++) {
            {
                this.newLine();
            }
        }
        return this;
    }
    clear(number) {
        if (typeof number === 'number' || number === null) {
            return this.clear$int(number);
        }
        else if (number === undefined) {
            return this.clear$();
        }
        else
            throw new Error('invalid overload');
    }
    space() {
        this.schedule(() => {
            this.output.print(' ');
        });
        return this;
    }
    resetColor() {
        this.schedule(() => {
            this.output.setStyle(Output.Style.RESET);
        });
        return this;
    }
    red() {
        this.schedule(() => {
            this.output.setStyle(Output.Style.RED);
        });
        return this;
    }
    green() {
        this.schedule(() => {
            this.output.setStyle(Output.Style.GREEN);
        });
        return this;
    }
    yellow() {
        this.schedule(() => {
            this.output.setStyle(Output.Style.YELLOW);
        });
        return this;
    }
    underline() {
        this.schedule(() => {
            this.output.setStyle(Output.Style.UNDERLINED);
        });
        return this;
    }
}
IOController['__class'] = 'de.coco.mindLinq.helper.IOController';
