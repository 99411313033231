import { IItem } from './IItem';
export class Bone extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return true;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return -10;
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Dieser Knochen sieht aus wie ein Menschenknochen.');
    }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return true;
    }
    /**
     *
     */
    onEat() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Du wolltest schon immer mal einen Knochen essen.');
        this.context
            .getView()
            .console()
            .println$java_lang_String('Aber war es das wert?');
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Knochen';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Dich beschleicht das Gef\u00fchl, dass es sich hierbei um einen Menschenknochen handelt.');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
}
Bone['__class'] = 'de.coco.mindLinq.world.items.Bone';
