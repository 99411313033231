import { IItem } from '../IItem';
export class Assaultrifle extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Sturmgewehr';
    }
    /**
     *
     */
    onUseFromWorld() { }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'n';
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() { }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return false;
    }
    /**
     *
     */
    onEat() { }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return false;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 225;
    }
}
Assaultrifle['__class'] = 'de.coco.mindLinq.world.items.weapons.Assaultrifle';
