export class TileTypes {
    constructor() { }
}
TileTypes.GRASS = 'grassland';
TileTypes.FOREST = 'forest';
TileTypes.TUNDRA = 'tundra';
TileTypes.DESERT = 'desert';
TileTypes.VOLCANIC = 'volcanic';
TileTypes.WATER = 'water';
TileTypes.LAVA = 'lava';
TileTypes['__class'] = 'de.coco.mindLinq.world.TileTypes';
