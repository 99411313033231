import { IItem } from './IItem';
export class Flower extends IItem {
    constructor(context) {
        super(context);
        this.flowerPlus = false;
    }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return true;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return false;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return this.flowerPlus ? 60 : 5;
    }
    /**
     *
     */
    onDrop() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Durch das Sch\u00fctteln hat sich die Heilkraft der Blume potenziert!');
        this.flowerPlus = true;
    }
    /**
     *
     */
    onTake() {
        if (this.context.getRandom().nextInt(100) < 30)
            this.context
                .getView()
                .ai()
                .comment$java_lang_String('Da mag wohl jemand Blumen?');
    }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return false;
    }
    /**
     *
     */
    onEat() {
        if (!this.flowerPlus) {
            this.context
                .getView()
                .console()
                .println$java_lang_String('W\u00e4hrend du auf der Blume kaust, denkst du \u00fcber den Sinn deines Lebens nach.')
                .delay(800);
        }
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Blume' + (this.flowerPlus ? '+' : '');
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Das ist eine sehr sch\u00f6ne Blume.');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'f';
    }
}
Flower['__class'] = 'de.coco.mindLinq.world.items.Flower';
