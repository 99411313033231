import { IItem } from '../IItem';
export class Chainsaw extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Kettens\u00e4ge';
    }
    /**
     *
     */
    onUseFromWorld() { }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'f';
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() {
        this.context
            .getView()
            .ai()
            .comment$java_lang_String('Oh. Jetzt wird es lustig!');
    }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return this.context.getRandom().nextInt(3) < 1;
    }
    /**
     *
     */
    onEat() { }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return false;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return true;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 155;
    }
}
Chainsaw['__class'] = 'de.coco.mindLinq.world.items.weapons.Chainsaw';
