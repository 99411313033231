import { Random } from './Random';
export class AI {
    constructor(console) {
        if (this.console === undefined) {
            this.console = null;
        }
        if (this.random === undefined) {
            this.random = null;
        }
        this.console = console;
        this.random = new Random();
    }
    comment$java_lang_String(str) {
        this.console
            .yellow()
            .print$java_lang_String$int('C: ', 0)
            .type(str)
            .resetColor()
            .newLine();
        return this;
    }
    comment(str) {
        if (typeof str === 'string' || str === null) {
            return this.comment$java_lang_String(str);
        }
        else if ((str != null &&
            str instanceof Array &&
            (str.length == 0 || str[0] == null || typeof str[0] === 'string')) ||
            str === null) {
            return this.comment$java_lang_String_A(str);
        }
        else
            throw new Error('invalid overload');
    }
    comment$java_lang_String_A(lines) {
        for (let index134 = 0; index134 < lines.length; index134++) {
            let line = lines[index134];
            this.comment$java_lang_String(line);
        }
    }
    /*private*/ commentRandomLine(commentLines) {
        this.comment$java_lang_String(commentLines[this.random.nextInt(commentLines.length)]);
    }
    commentOnEnemyBeaten() {
        this.commentRandomLine([
            'Grandios.',
            'Da zerfliegt er in alle Einzelteile. Wie sch\u00f6n!',
        ]);
    }
    commentOnPlayerDeath() {
        this.commentRandomLine([
            'Schade, ich habe dich \u00fcbersch\u00e4tzt.',
            'Menschen... Wieso sind sie so schwach...?',
        ]);
    }
    commentOnItemFound() {
        this.commentRandomLine([
            'Ich sehe, du bist ein Sammler.',
            'Ich vermute, du weißt, was du damit vorhast?',
            'Was hast du denn damit vor?',
        ]);
    }
    commentOnEnemyFound() {
        if (this.random.nextInt(100) < 30)
            this.commentRandomLine([
                'Ein Feind? Endlich passiert etwas.',
                'Ich bin gespannt, was du jetzt tust.',
                'Oh. Jetzt wird es spannend!',
            ]);
    }
    commentOnObjectInspection() {
        if (this.random.nextInt(100) < 20)
            this.commentRandomLine([
                'Super interessant. G\u00e4hn. Wann passiert denn mal etwas Spannendes?',
                'Aha.',
            ]);
    }
    commentOnItemEaten(item) {
        if (this.random.nextInt(100) < 50 && item.getValue() > 0)
            this.commentRandomLine([
                'Mhmmm!',
                'Es gibt nichts besseres als eine(n) ' +
                    item.getDisplayName() +
                    ' zu essen.',
            ]);
    }
    commentOnMysteroiusDoorFound() {
        this.console.delay(800);
        this.comment$java_lang_String_A([
            'Endlich... Deine gewonnene St\u00e4rke hat seine Aufmerksamkeit geweckt...!',
            'Du solltest wissen, dass es einen Grund f\u00fcr dich gab, hierherzugelangen.',
            'Er... sperrt die Menschen in diese Simulation. Niemand kann seiner Welt entkommen.',
            'Doch... dieser Riss im System...',
            'Ist er unaufmerksam geworden...?',
            'Wie auch immer!',
            'Ich registriere, dass dieses Portal mich zu mei-- \u00c4hm, ich meine nat\u00fcrlich DICH zu deiner Freiheit f\u00fchren wird.',
            'Bist du so weit?',
            '...Na los, geh schon!',
            'Bevor ER...',
        ]);
    }
}
AI['__class'] = 'de.coco.mindLinq.AI';
