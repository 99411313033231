import { IItem } from '../IItem';
export class Branch extends IItem {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Stock';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context.getView().console().println$java_lang_String('Ein Stock.');
        if (this.context.getRandom().nextBoolean())
            this.context
                .getView()
                .ai()
                .comment$java_lang_String('Was hast du damit vor?');
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
    /**
     *
     */
    onDrop() { }
    /**
     *
     */
    onTake() { }
    /**
     *
     * @return {boolean}
     */
    onUseFromInventory() {
        return true;
    }
    /**
     *
     */
    onEat() { }
    /**
     *
     * @return {boolean}
     */
    isEdible() {
        return false;
    }
    /**
     *
     * @return {boolean}
     */
    isActivator() {
        return true;
    }
    /**
     *
     * @return {number}
     */
    getValue() {
        return 20;
    }
}
Branch['__class'] = 'de.coco.mindLinq.world.items.weapons.Branch';
