import { IWorldObject } from '../IWorldObject';
export class MysteriousDoor extends IWorldObject {
    constructor(context) {
        super(context);
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Portal';
    }
    /**
     *
     */
    onUseFromWorld() {
        this.context
            .getView()
            .console()
            .println$java_lang_String('Langsam n\u00e4herst du dich dem wabernden Riss in der Welt.')
            .delay(800);
        this.context
            .getView()
            .console()
            .println$java_lang_String('Du l\u00e4ufst durch das Licht, bis es dich entg\u00fcltig umh\u00fcllt.')
            .delay(800);
        this.context
            .getView()
            .console()
            .println$java_lang_String('Stille.')
            .delay(1000);
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'n';
    }
}
MysteriousDoor['__class'] = 'de.coco.mindLinq.world.objects.MysteriousDoor';
