<script lang="ts">
	import { onMount } from 'svelte';
	import Screen from './components/screen/Screen.svelte';
	import ScreenText from './components/screen/ScreenText.svelte';
	import TextInput from './components/TextInput.svelte';
	import { Game } from './mindLinq/Game';
	import { GameStats } from './mindLinq/GameStats';
	import { IOController } from './mindLinq/helper/IOController';
	import { Output } from './mindLinq/helper/Output';
	import { Intro } from './mindLinq/Intro';
	import { View } from './mindLinq/View';

	export let name: string;

	const backgroundStartup = new Audio('/sounds/background_noise.mp3');
	const backgroundLoop = new Audio('/sounds/background_noise2.mp3');
	backgroundLoop.loop = true;

	let windowHeight: number;
	let windowWidth: number;

	let lines = [];
	$: maxNumberOfLines = windowHeight / 10 || 100;
	let currentLine = '';
	const defaultSuggestions = ['w', 'n', 's', 'e'];
	let suggestions = defaultSuggestions;
	let inputRequested = false;

	let sendInput: (input: string) => void = null;

	onMount(() => {
		const stats: GameStats = new GameStats();
		const view: View = new View(
			new IOController(
				{
					onInput(listener) {
						inputRequested = true;
						sendInput = listener.input;
					},
				},
				{
					print(text) {
						if (currentStyle)
							currentLine += `<span class="${currentStyle}">${text}</span>`;
						else currentLine += text;
					},
					newLine() {
						println(currentLine);
						currentLine = '';
						generateSuggestions();
					},
					clearLine() {
						currentLine = '';
					},
					setStyle(style) {
						if (style === Output.Style.RESET) currentStyle = null;
						else currentStyle = style;
					},
				}
			),
			stats
		);
		view
			.console()
			.println$java_lang_String('Zum Starten Enter dr\u00fccken...');
		view
			.console()
			.println$java_lang_String(
				'("skip" eingeben, um das Intro zu \u00fcberspringen)'
			);
		view
			.console()
			.getInput()
			.then(async (input) => {
				view.console().clear$();
				if (!(input.toLowerCase() === 'skip')) {
					backgroundStartup.play().then(() => {
						backgroundLoop.play();
					});
					const intro: Intro = new Intro(view);
					await intro.play();
				} else {
					backgroundLoop.play();
				}
				const game: Game = new Game(view, stats);
				game.start();
			});
	});

	let currentStyle: Output.Style = null;

	let screenElement: HTMLElement = null;

	function println(text: string) {
		lines = [...lines, text].slice(lines.length - maxNumberOfLines);
	}

	// hacky way to generate autocompletions for items
	function generateSuggestions() {
		if (!screenElement) return;
		const newSuggestions = new Set<string>();
		const objects = Array.from(screenElement.querySelectorAll('.UNDERLINED'));
		objects
			.slice(objects.length - 5)
			.reverse()
			.forEach((e) => {
				newSuggestions.add('u ' + e.textContent.toLowerCase());
				newSuggestions.add('t ' + e.textContent.toLowerCase());
			});
		lines
			.slice(lines.length - 7)
			.reverse()
			.forEach((line: string) => {
				const inventoryTexts = line.match(/Inventar: \[[^\[]*\]/);
				if (inventoryTexts && inventoryTexts.length > 0) {
					inventoryTexts[0]
						.replace('Inventar: ', '')
						.replace('[', '')
						.replace(']', '')
						.split(',')
						.forEach((item) => {
							const _item = item.trim().toLowerCase();
							if (_item) {
								newSuggestions.add('d ' + _item);
								newSuggestions.add('u ' + _item);
							}
						});
					return;
				}
			});
		suggestions = [...defaultSuggestions, ...Array.from(newSuggestions)];
	}
	function resetSuggestions() {
		suggestions = defaultSuggestions;
	}
</script>

<svelte:window bind:innerHeight={windowHeight} bind:innerWidth={windowWidth} />

<main>
	<Screen
		disablePadding
		height={windowHeight}
		width={windowWidth}
		turnedOn={true}
	>
		<div class="screen" bind:this={screenElement}>
			<ScreenText>
				{#each lines as line}
					<p>
						{@html line}
					</p>
				{/each}
				<p>
					{@html currentLine}
				</p>
				<div style={`opacity: ${inputRequested ? 1 : 0}`}>
					<TextInput
						alwaysKeepFocused
						autofocus
						{suggestions}
						on:input={({ detail }) => {
							if (!inputRequested) return;
							println('>' + detail);
							sendInput(detail.toLowerCase());
							inputRequested = false;
						}}
						multiInput
					/>
				</div>
			</ScreenText>
		</div>
	</Screen>
</main>

<style lang="scss">
	.screen {
		padding: 30px;
		max-width: 1280px;
	}
	:global(.YELLOW) {
		color: yellow;
	}
	:global(.RED) {
		color: rgb(243, 126, 79);
	}
	:global(.GREEN) {
		color: rgb(53, 255, 53);
	}
	:global(.UNDERLINED) {
		text-decoration: underline;
	}

	p {
		margin: 0.5em 0;
		line-height: 1.5em;
		word-break: keep-all;
	}
</style>
