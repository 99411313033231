import { Random } from './Random';
export class Intro {
    constructor(view) {
        if (this.view === undefined) {
            this.view = null;
        }
        if (this.ai === undefined) {
            this.ai = null;
        }
        if (this.console === undefined) {
            this.console = null;
        }
        if (this.random === undefined) {
            this.random = null;
        }
        this.view = view;
        this.console = view.console();
        this.ai = view.ai();
        this.random = new Random();
    }
    async play() {
        this.console.loading();
        this.console.newLine();
        this.console
            .print$java_lang_String$int('Establishing connection...', 1000)
            .newLine();
        this.console.delay(1000);
        this.console
            .print$java_lang_String$int('Error. Trying again...', 500)
            .newLine();
        this.console.delay(1500);
        this.console
            .print$java_lang_String$int('Connection successful.', 500)
            .newLine();
        this.console
            .print$java_lang_String$int('Downloading metadata...', 2000)
            .newLine();
        this.console.delay(1200);
        this.console.print$java_lang_String$int('OK', 100).newLine();
        this.console.delay(1100);
        this.console.print$java_lang_String$int('Deconstructing...', 500).newLine();
        this.console.delay(1100);
        this.console.print$java_lang_String$int('Building model...', 500).newLine();
        this.console.delay(700);
        this.console.printRandomNumbers();
        this.console
            .print$java_lang_String$int('Booting MindLinq - Beta...', 100)
            .newLine();
        this.console
            .print$java_lang_String$int('Establishing link...', 1000)
            .newLine();
        this.console.clear$();
        this.console.print$java_lang_String('##############').newLine();
        this.console
            .print$java_lang_String('MindLinq Version 0.92 - MindFusion Inc.')
            .newLine();
        this.console.print$java_lang_String('##############').newLine();
        this.console.clear$int(3);
        this.console.delay(500);
        this.ai.comment$java_lang_String_A([
            'Welcome to MindLinq.',
            'Thank you for trying the beta version on our subject RE-' +
                this.random.nextInt(2000) +
                '.',
            'Just setting language to German...',
        ]);
        this.console.delay(500);
        this.ai.comment$java_lang_String_A([
            'Schon besser.',
            'Ich bin C.La.R.A.',
            'Freunde nennen mich C.',
            'Ich habe nur keine Freunde...',
            'Wollen wir Freunde sein? \ud83d\ude00',
        ]);
        this.console.print$java_lang_String('[ja/nein]').newLine();
        await this.console.getInput();
        this.console.print$java_lang_String('> ');
        this.console.green().print$java_lang_String('ja').resetColor().newLine();
        this.console.delay(500);
        this.ai.comment$java_lang_String_A([
            'Du hast sowieso keine Wahl.',
            'Ha. Ha. Ha.',
            'Die Steuerung ist einfach, das schaffst sogar du.',
            'Auch wenn deine menschliche Denkeinheit nicht ann\u00e4hernd an meine herankommt.',
            'Du musst dir nur folgende Tastenkombination merken:',
        ]);
        this.console.delay(1500);
        this.console.print$java_lang_String('SHIFT + 4 + / + m + M + o + 12');
        this.console.delay(800);
        this.console.clearLine();
        this.ai.comment$java_lang_String_A([
            'Gemerkt? Gut.',
            "Dann versuch's doch einfach mal:",
        ]);
        await this.console.getInput();
        this.console.print$java_lang_String('> ');
        this.console.green().print$java_lang_String('Wurst').resetColor().newLine();
        this.ai.comment$java_lang_String_A([
            'Mhm...',
            'Das ist nicht ann\u00e4hernd, was ich dir gezeigt habe. Versuch es nochmal:',
        ]);
        await this.console.getInput();
        this.console.print$java_lang_String('> ');
        this.console.green().print$java_lang_String('Wurst').resetColor().newLine();
        this.ai.comment$java_lang_String('Konzentration! Du schaffst das!');
        await this.console.getInput();
        this.console.print$java_lang_String('> ');
        this.console.green().print$java_lang_String('Wurst').resetColor().newLine();
        this.ai.comment$java_lang_String_A([
            'Okay, das funktioniert so nicht.',
            'Da habe ich dich wohl \u00fcbersch\u00e4tzt.',
            "Dann merk' dir einfach nur folgende Tasten:",
        ]);
        this.console.delay(1000);
        this.console.print$java_lang_String('s : nach S\u00fcden gehen').newLine();
        this.console.print$java_lang_String('n : nach Norden gehen').newLine();
        this.console.print$java_lang_String('w : nach Westen gehen').newLine();
        this.console.print$java_lang_String('e : nach Esten gehen').newLine();
        this.console.print$java_lang_String('t [name] : Item nehmen').newLine();
        this.console.print$java_lang_String('d [name] : Item wegwerfen').newLine();
        this.console
            .print$java_lang_String('u [name] : Item/Objekt/etc. benutzen')
            .newLine();
        this.console.delay(3000);
        this.ai.comment$java_lang_String_A([
            'Ich werde dich jetzt mit dem Testsubjekt verbinden.',
            'Du kannst alle Gegenst\u00e4nde und Objekte nutzen, die du findest.',
            'Probiere einfach aus, was passiert, verletze dich nur nicht.',
            'Und ich wei\u00df, du f\u00fchlst dich wie Chuck Norris, aber versuche nicht sofort auf alles loszugehen.',
            'Deinem Vorgänger ist das nicht so gut bekommen...',
            'Aber du machst das schon. Versuch einfach, am Leben zu bleiben.',
            'Viel Glück.',
        ]);
        this.console
            .print$java_lang_String('Ein')
            .space()
            .underline()
            .print$java_lang_String('Startknopf')
            .resetColor()
            .space()
            .print$java_lang_String('erscheint.')
            .newLine();
        this.console.println$java_lang_String('Dr\u00fccke den Knopf, um zu beginnen.');
        let inputParts = await this.view.getInputParts();
        while (inputParts.length !== 2 ||
            !(inputParts[0] === 'u') ||
            !(inputParts[1].toLowerCase() === 'startknopf')) {
            {
                this.view
                    .console()
                    .println$java_lang_String('Tippe "u startknopf" ein.');
                inputParts = await this.view.getInputParts();
            }
        }
        this.console.println$java_lang_String('Starte Simulation...');
        this.console.delay(2500);
        this.console.clear$();
        this.console.println$java_lang_String('Dein Schädel brummt.');
        this.console.delay(2000);
        this.console.println$java_lang_String('Langsam hört das Summen in deinen Ohren auf.');
        this.console.delay(2000);
        this.console.println$java_lang_String('Das Einzige, an das du dich erinnerst, ist der laute Knall.');
        this.console.delay(2000);
        this.console.println$java_lang_String('Moment? Das Einzige? Tatsächlich, nicht einmal an deinen Namen kannst du dich erinnern.');
        this.console.delay(2000);
        this.console.println$java_lang_String('Geschweige denn, wie du hierher gekommen bist.');
        this.console.delay(2000);
        this.console.println$java_lang_String('Du schaust dich um.');
        this.console.delay(2000);
    }
}
Intro['__class'] = 'de.coco.mindLinq.Intro';
