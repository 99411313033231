import { Key } from '../items/Key';
import { IMob } from './IMob';
export class GiantSandworm extends IMob {
    constructor(context) {
        super(context);
        this.health = 420;
    }
    /**
     *
     * @return {string}
     */
    getDisplayName() {
        return 'Riesensandwurm';
    }
    /**
     *
     * @return {number}
     */
    getStrength() {
        if (this.context.getRandom().nextBoolean()) {
            this.context
                .getView()
                .console()
                .println$java_lang_String('Der Sandwurm \u00fcbersch\u00fcttet dich.')
                .delay(1000)
                .println$java_lang_String('Mit Sand.')
                .delay(800);
            return 50;
        }
        return 36;
    }
    /**
     *
     * @return {number}
     */
    getHealth() {
        return this.health;
    }
    /**
     *
     * @param {number} health
     */
    setHealth(health) {
        this.health = health;
    }
    /**
     *
     * @return {IItem}
     */
    getDrop() {
        const n = this.context.getRandom().nextInt(100);
        if (n < 70) {
            return new Key(this.context);
        }
        return null;
    }
    /**
     *
     * @return {number}
     */
    getExperience() {
        return 1200;
    }
    /**
     *
     */
    onBeaten() { }
    /**
     *
     */
    onUseFromWorld() {
        this.context.getView().mobInspection(this);
    }
    /**
     *
     * @return {string}
     */
    getGender() {
        return 'm';
    }
}
GiantSandworm['__class'] = 'de.coco.mindLinq.world.mobs.GiantSandworm';
